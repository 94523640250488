import React from 'react'
import Madfit_logo from '../assets/images/images/madfit-logo.svg'
import { Link } from 'react-router-dom';

const Survey = () => {
  return (
    <>
      
      <section class="survey-sec survey-outer">

<div class="top-l-circle">
  <img src={require("../assets/images/images/full-circle2.png")} alt="full-circle"/>
</div>

<div class="bottom-r-circle">
  <img src={require("../assets/images/images/full-circle2.png")} alt="full-circle"/>
</div>

 <div class="survey-area survey">
     <div class="survey-img">
         <img src={require("../assets/images/images/survey-in.jpg")} alt="Survey-landing"/>
     </div>

     <div class="survey-content">
        <div class="survey-content-inner">
        <a href="#" class="servey-logo"><img src={Madfit_logo} alt="MadFit-logo"/></a>
        <h3>We Recommend the</h3>
        <h1>strong at home challenge </h1>
        <p>Orem ipsum dolor sit amet, consectetur adipiscing elit. Donec eleifend lectus vel neque interdum viverra.</p>

        <p>Etiam pulvinar sed urna eu fermentum. Aliquam ac elementum eros, in interdum odio. Aenean ligula neque, interdum ac finibus quis.</p>
        <Link to="/" class="site-btn take-servey">Join Now</Link>
        <p class="oramipsum"> <strong> Orem ipsum dolor sit amet, consectetur adipiscing.</strong><br/> 
Aliquam tristique enim non erat imperdiet, a euismod arcu eleifend. Quisque suscipit lacinia orci eu eleifend. Nullam gravida semper velit, sed pharetra.Phasellus.</p>
        </div>
     </div>
     

 </div>

</section>
    </>
  )
}

export default Survey
