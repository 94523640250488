// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer .footer_inner{
    min-height: 52px;
    background: var(--black_bold);
    /* position: relative; */
}
.footer_inner .footer_inner_c {
    /* display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    height:100% */
    /* position: relative; */
  
  }
  .footer_inner .footer_inner_c 
  .footer_inner_c_text{
    font-size: 15px;
  line-height: 30px;
/* or 200% */

text-align: center;

color: #FFFFFF;
  }
  .footer_inner .footer_inner_c 
  .footer_inner_c_text{
  position: absolute;
  /* max-width: 274px; */
  /* height: 21px; */
  /* top: 7px; */
  margin: 0 auto;
  left: 0;
  right: 0;
  top:50%;
  transform: translateY(-50%);


  }
  .footer_inner .footer_inner_c 
  .footer_inner_c_text span{
    padding:0 10px 0px 12px
  }
  .footer a{
    color:inherit
  }
`, "",{"version":3,"sources":["webpack://./src/assets/css/footer.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,6BAA6B;IAC7B,wBAAwB;AAC5B;AACA;IACI;;;;iBAIa;IACb,wBAAwB;;EAE1B;EACA;;IAEE,eAAe;EACjB,iBAAiB;AACnB,YAAY;;AAEZ,kBAAkB;;AAElB,cAAc;EACZ;EACA;;EAEA,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;EACd,cAAc;EACd,OAAO;EACP,QAAQ;EACR,OAAO;EACP,2BAA2B;;;EAG3B;EACA;;IAEE;EACF;EACA;IACE;EACF","sourcesContent":[".footer .footer_inner{\r\n    min-height: 52px;\r\n    background: var(--black_bold);\r\n    /* position: relative; */\r\n}\r\n.footer_inner .footer_inner_c {\r\n    /* display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    width:100%;\r\n    height:100% */\r\n    /* position: relative; */\r\n  \r\n  }\r\n  .footer_inner .footer_inner_c \r\n  .footer_inner_c_text{\r\n    font-size: 15px;\r\n  line-height: 30px;\r\n/* or 200% */\r\n\r\ntext-align: center;\r\n\r\ncolor: #FFFFFF;\r\n  }\r\n  .footer_inner .footer_inner_c \r\n  .footer_inner_c_text{\r\n  position: absolute;\r\n  /* max-width: 274px; */\r\n  /* height: 21px; */\r\n  /* top: 7px; */\r\n  margin: 0 auto;\r\n  left: 0;\r\n  right: 0;\r\n  top:50%;\r\n  transform: translateY(-50%);\r\n\r\n\r\n  }\r\n  .footer_inner .footer_inner_c \r\n  .footer_inner_c_text span{\r\n    padding:0 10px 0px 12px\r\n  }\r\n  .footer a{\r\n    color:inherit\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
