import React,{useEffect} from 'react'
import circle from "../assets/images/home/circle.svg"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
import Header from '../components/Header'
import Footer from '../components/Footer'
// import apple_logo from '../assets/images/home/app-store.svg'
import apple_logo from '../assets/images/home/app-store-new.svg'
import home_circle from '../assets/images/home/circle.svg'
import { Link, Navigate } from 'react-router-dom';
// import footer_logo from '../assets/images/home/MF_Logo-Solo-Black_1.svg'
import footer_logo from '../assets/images/home/footer-logo-madfit.svg'

import { useLocation,useNavigate } from 'react-router-dom';




import $ from 'jquery'
// import ScrollToTop from '../components/ScrollToTop';


// const [rerender, setRerender] = useState()


const Home = () => {


 
 


    const settings = {
        className: "center",
      centerMode: true,
        dots: true,
        infinite: true,
        speed: 200,
        // centerPadding: "50px",
        
        responsive: [
            // {
            //   breakpoint: 1024,
            //   settings: {
            //     slidesToShow: 3,
            //     slidesToScroll: 3,
            //     infinite: true,
            //     dots: true
            //   }
            // },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]

      };

      const settings_app = {
        //     className: "center",
        //   centerMode: true,
            dots: true,
            
            infinite: false,
            // speed: 200,
            // centerPadding: "50px",
            // initialSlide: 1,
            slidesToShow: 2,
            // slidesToScroll: 1,
            
            responsive: [
                // {
                //   breakpoint: 1024,
                //   settings: {
                //     slidesToShow: 3,
                //     slidesToScroll: 3,
                //     infinite: true,
                //     dots: true
                //   }
                // },
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                },
                {
                  breakpoint: 640,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                }
              ]
    
          };






          const settings_feedback={
               //     className: "center",
        //   centerMode: true,
        dots: true,
        infinite: true,
        speed: 200,
        // centerPadding: "50px",
        
        responsive: [
            // {
            //   breakpoint: 1024,
            //   settings: {
            //     slidesToShow: 3,
            //     slidesToScroll: 3,
            //     infinite: true,
            //     dots: true
            //   }
            // },
            // {
            //   breakpoint: 991,
            //   settings: {
            //     slidesToShow: 2,
            //     slidesToScroll: 1,
            //     initialSlide: 2
            //   }
            // },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]

          }

          const  setting_review={
            dots: false,
            infinite: true,
            speed: 200,
            // slidesToScroll: 2,
            vertical: true,
            verticalSwiping: true,
            slidesToShow: 2,
            // slidesToScroll: 2

          }

  return (
    <>
    {/* <ScrollToTop/> */}
<Header/>
<section class="home-hero-sec">
    <div class="container">
    
       <div class="row">
       {/* {process.env.REACT_APP_DESCRIPTION} */}
          <div class="col-xl-5 col-lg-6">
            <div class="home-hero-cont">
                <span class="ban-sub-head">Jump into a healthier lifestyle</span>
                <h1>TRY MADFIT FOR ONLY $1</h1>
                <p>Achieve your fitness goals with the MadFit app! Madfit offers a balanced approach to health and fitness that will leave you feeling your best! </p>
                <Link to="/sign-up" class="site-btn"> Start Now </Link>

            </div>
          </div>
          {/* url(${require("../assets/images/home/circle.svg")}) */}
          <div class="col-xl-7 col-lg-6">
          {/* style={{backgroundImage:  `url(${require("../assets/images/home/circle.svg")})` }} */}
          {/* style={{backgroundImage: `url(${require("../assets/images/images/full-circle2.png")})`}} */}
            <div class="full-circle-area hero-circle"   style={{backgroundImage: `url(${home_circle})`}}>
              <img src={ require("../assets/images/images/Nutrition.png")} alt=""/>
             
            </div>
          </div>
       </div>
    </div>
 </section>

 <section class="terget-body">
   <div class="container">
      <div class="row">
         <div class="col-lg-6">
            <h2>Target Your Body & mind</h2>
         </div>
         <div class="col-lg-6">
           <p>A growing collection of guided video workouts with styles ranging from high intensity strength training and cardio to slower-paced, mindfulness inspired workouts like yoga and pilates.</p>
         </div>
         <div class="col-lg-12">
          <Link to="/sign-up" class="site-btn take-servey">Join Our Classes</Link>
         </div>
      </div>
      <div class="target-carousel  target-carausal-desktop">
         
            <div class="target-row">
            <img class="terget1img" src= { require("../assets/images/home/terget2.jpg")} alt="terget1"/>
               {/* <img class="terget1img" src= { require("../assets/images/home/terget1.jpg")} alt="terget1"/>  */}
               {/* { require("../assets/images/home/terget1.jpg")} */}
               <div class="target-content">
                   <img class="terget-circle" src={circle} alt=""/>
                   <img class="terget-circle terget-circle-blue" src={ require("../assets/images/images/full-circle2.png")} alt=""/>
                  
                   <h2>Challenges</h2>
                   <p>Carefully crafted challenges to target your personal goals.<div class="lernmorebtns"> <Link to="/feature">Learn More </Link> </div> </p>
               </div>
            </div>
        

            <div class="target-row">
            <img class="terget1img" src= { require("../assets/images/home/terget1.jpg")} alt="terget1"/> 
              {/* <img class="terget1img" src= { require("../assets/images/home/terget2.jpg")} alt="terget1"/>  */}
             
               <div class="target-content">
                   <img class="terget-circle" src={circle} alt=""/>
                   <img class="terget-circle terget-circle-blue" src={ require("../assets/images/images/full-circle2.png")} alt=""/>
                   <h2>Yoga</h2>
                   <p>A selection of various yoga routines to relax & inspire.<div class="lernmorebtns"> <Link to="/feature">Learn More </Link> </div> </p>
               </div>
            </div>
   

            <div class="target-row">
              <img class="terget1img" src= { require("../assets/images/images/Mindfulness.png")} alt="terget1"/> 
               <div class="target-content">
                   <img class="terget-circle" src={circle} alt=""/>
                   <img class="terget-circle terget-circle-blue" src={ require("../assets/images/images/full-circle2.png")} alt=""/>
                   <h2>Mindfulness</h2>
                   <p>Build healthy habits for your mental health as part of your routine. <div class="lernmorebtns"> <Link to="/feature">Learn More </Link> </div></p>
               </div>
            </div>
        

            <div class="target-row">
              <img class="terget1img" src={ require("../assets/images/images/video_classes.png")} alt="terget1"/> 
               <div class="target-content">
                   <img class="terget-circle" src={circle} alt=""/>
                   <img class="terget-circle terget-circle-blue" src={ require("../assets/images/images/full-circle2.png")} alt=""/>
                   <h2>Video Classes</h2>
                   <p>A huge collection of guided exercises for you to choose at your pace.<div class="lernmorebtns">
 <Link to="/feature">Learn More </Link> </div> </p>
               </div>
            </div>
            <div class="target-row">
              <img class="terget1img" src= { require("../assets/images/images/Nutrition.png")} alt="terget1"/> 
               <div class="target-content">
                   <img class="terget-circle" src={circle} alt=""/>
                   <img class="terget-circle terget-circle-blue" src={ require("../assets/images/images/full-circle2.png")} alt=""/>
                   <h2>Nutrition</h2>
                   <p>Fuel your body the right way with our recipes for every meal. <div class="lernmorebtns"> <Link to="/feature">Learn More </Link> </div> </p>
               </div>
            </div>
            

         </div>
     
         <div class="target-carousel  target-carausal-mobile body-mind">
         <Slider {...settings}>
            <div class="target-row">
               <img class="terget1img" src= { require("../assets/images/home/terget2.jpg")} alt="terget1"/> 
               {/* { require("../assets/images/home/terget1.jpg")} */}
               <div class="target-content">
                   <img class="terget-circle" src={circle} alt=""/>
                   <img class="terget-circle terget-circle-blue" src={ require("../assets/images/images/full-circle2.png")} alt=""/>
                  
                   <h2>Challenges</h2>
                   <p>Carefully crafted challenges to target your personal goals. <Link to="/feature">Learn More </Link></p>
               </div>
            </div>
        

            <div class="target-row">
              <img class="terget1img" src= { require("../assets/images/home/terget1.jpg")} alt="terget1"/> 
             
               <div class="target-content">
                   <img class="terget-circle" src={circle} alt=""/>
                   <img class="terget-circle terget-circle-blue" src={ require("../assets/images/images/full-circle2.png")} alt=""/>
                   <h2>Yoga</h2>
                   <p>A selection of various yoga routines to relax & inspire. <Link to="/feature">Learn More </Link></p>
               </div>
            </div>
   

            <div class="target-row">
              <img class="terget1img" src= { require("../assets/images/images/Mindfulness.png")} alt="terget1"/> 
               <div class="target-content">
                   <img class="terget-circle" src={circle} alt=""/>
                   <img class="terget-circle terget-circle-blue" src={ require("../assets/images/images/full-circle2.png")} alt=""/>
                   <h2>Mindfulness</h2>
                   <p>Build healthy habits for your mental health as part of your routine. <Link to="/feature">Learn More </Link></p>
               </div>
            </div>
        

            <div class="target-row">
              <img class="terget1img" src={ require("../assets/images/images/video_classes.png")} alt="terget1"/> 
               <div class="target-content">
                   <img class="terget-circle" src={circle} alt=""/>
                   <img class="terget-circle terget-circle-blue" src={ require("../assets/images/images/full-circle2.png")} alt=""/>
                   <h2>Video Classes</h2>
                   <p>A huge collection of guided exercises for you to choose at your pace. <Link to="/feature">Learn More </Link></p>
               </div>
            </div>

            <div class="target-row">
              <img class="terget1img" src= { require("../assets/images/images/Nutrition.png")} alt="terget1"/> 
               <div class="target-content">
                   <img class="terget-circle" src={circle} alt=""/>
                   <img class="terget-circle terget-circle-blue" src={ require("../assets/images/images/full-circle2.png")} alt=""/>
                   <h2>Nutrition</h2>
                   <p>Fuel your body the right way with our recipes for every meal. <Link to="/feature">Learn More </Link></p>
               </div>
            </div>
            </Slider>

         </div>







   </div>
 </section>

 <section class="expert-sec">
   <div class="container">
       <div class="expert-inner">
       <img src={circle} class="circle-img-expert" alt=""/>
       <div class="row">
           <div class="col-lg-6">
               <div class="expert-cont">
                   <h2>Meet our expert trainers</h2>
                   <p>Get support, knowledge and encouragement from our team as Maddie and Arianna guide you through specially designed exercises, challenges and programs that will help you reach your goals. </p>
                   <Link to="/trainer" class="site-btn take-servey">Learn More</Link>
               </div>
           </div>
           <div class="col-lg-6">
               <div class="expert-carousel">
               <Slider {...settings_app}>
                   
                   <div class="expert-item">
                       <img src={require("../assets/images/home/expert1.jpg")} alt=" Expert photo"/>
                       <h4>Maddie</h4>
                       <p>MadFit founder and fitness expert. Maddie’s personal style is loved by over 10 million followers online.</p>
                   </div>
                   <div class="expert-item">
                       <img src={require("../assets/images/home/expert2.jpg")} alt="Expert photo"/>
                       <h4>Arianna</h4>
                       <p>MadFit’s certified yoga instructor. Arianna brings moving and spiritual yoga to the MadFit library.</p>
                   </div>
                   
                   </Slider>
               </div>
           </div>
       </div>
      </div>
   </div>
</section>


 <section class="terget-body class_app">
  <div class="container">
     <div class="row">
        <div class="col-lg-6">
           <h2>a world-class app</h2>
        </div>
        <div class="col-lg-6">
          <p>A custom-built, high-quality mobile app with a huge collection of features and tools to help you achieve your goals and stay focused on the most important areas of your fitness routine.</p>
       
          <Link to="/feature" class="site-btn take-servey">Learn More</Link>
        </div>
        <div class="col-lg-12">
        <Link role="link" target='_blank' to="https://apps.apple.com/app/madfit-home-workout-app/id1577283718" class="appstore home-appstore"><img src={apple_logo} alt=""/></Link>
        </div>
     </div>
     <div class="target-carousel app-carousel target-carausal-desktop">
        {/* <Slider {...settings}> */}
           <div class="target-row">
           
              <img class="terget1img" src={ require("../assets/images/home/mobile-helf2.png")} alt="terget1"/> 
              <div class="target-content">
                  <img class="terget-circle" src={circle} alt=""/>
                  <img class="terget-circle terget-circle-blue" src={ require("../assets/images/images/full-circle2.png")} alt=""/>
                  <h2>Tracking</h2>
                  <p>Keep your fitness flowing with tracking features built into the app. </p>
              </div>
           </div>
          

           <div class="target-row">
             <img class="terget1img" src={ require("../assets/images/home/journal_latest.png")} alt="terget1"/> 
              <div class="target-content">
                  <img class="terget-circle" src={circle} alt=""/>
                  <img class="terget-circle terget-circle-blue"  src={ require("../assets/images/images/full-circle2.png")} alt=""/>
                  <h2>Journal</h2>
                  <p>Record and reflect with journaling features such as a diary, selfies and gratitude boxes. </p>
              </div>
           </div>
          

           <div class="target-row">
             <img class="terget1img" src={require("../assets/images/home/mobile-helf.png")} alt="terget1"/> 
              <div class="target-content">
                  <img class="terget-circle" src={circle} alt=""/>
                  <img class="terget-circle terget-circle-blue" src={ require("../assets/images/images/full-circle2.png")} alt=""/>
                  <h2>Custom Plan</h2>
                  <p>Choose your own exercises, challenges and recipes and build out your own schedule. </p>
              </div>
           </div>
         

           <div class="target-row">
             <img class="terget1img" src={require("../assets/images/home/mobile-helf4.png")} alt="terget1"/> 
              <div class="target-content">
                  <img class="terget-circle" src={circle} alt=""/>
                  <img class="terget-circle terget-circle-blue"  src={ require("../assets/images/images/full-circle2.png")} alt=""/>
                  <h2>Updates</h2>
                  <p>The MadFit app never stays still. Frequent updates with exercises, recipes and features.</p>
              </div>
           </div>
           {/* </Slider> */}
          

        </div>
  
        <div class="target-carousel app-carousel target-carausal-mobile">
        <Slider {...settings}>
           <div class="target-row">
           
              <img class="terget1img" src={ require("../assets/images/home/mobile-helf2.png")} alt="terget1"/> 
              <div class="target-content">
                  <img class="terget-circle" src={circle} alt=""/>
                  <img class="terget-circle terget-circle-blue" src={ require("../assets/images/images/full-circle2.png")} alt=""/>
                  <h2>Tracking</h2>
                  <p>Keep your fitness flowing with tracking features built into the app. </p>
              </div>
           </div>
          

           <div class="target-row">
             <img class="terget1img" src={ require("../assets/images/home/journal_latest.png")} alt="terget1"/> 
              <div class="target-content">
                  <img class="terget-circle" src={circle} alt=""/>
                  <img class="terget-circle terget-circle-blue"  src={ require("../assets/images/images/full-circle2.png")} alt=""/>
                  <h2>Journal</h2>
                  <p>Record and reflect with journaling features such as a diary, selfies and gratitude boxes. </p>
              </div>
           </div>
          

           <div class="target-row">
             <img class="terget1img" src={require("../assets/images/home/mobile-helf.png")} alt="terget1"/> 
              <div class="target-content">
                  <img class="terget-circle" src={circle} alt=""/>
                  <img class="terget-circle terget-circle-blue" src={ require("../assets/images/images/full-circle2.png")} alt=""/>
                  <h2>Custom Plan</h2>
                  <p>Choose your own exercises, challenges and recipes and build out your own schedule. </p>
              </div>
           </div>
         

           <div class="target-row">
             <img class="terget1img" src={require("../assets/images/home/mobile-helf4.png")} alt="terget1"/> 
              <div class="target-content">
                  <img class="terget-circle" src={circle} alt=""/>
                  <img class="terget-circle terget-circle-blue"  src={ require("../assets/images/images/full-circle2.png")} alt=""/>
                  <h2>Updates</h2>
                  <p>The MadFit app never stays still. Frequent updates with exercises, recipes and features.</p>
              </div>
           </div>
           </Slider>
          

        </div>
     

  </div>
  
</section>


<section className="community-area">
<div className="container">
<div className="sec">
        <div className="section_inner section_inner_pos ">
        <img src={circle} class="circle-img-expert comunity-circle" alt=""/>
        <div className="row">
            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
<div className="section_inner_left">
<div className="section_com_header section_header_pos">
our community and their achievements
</div>
<div className="section_com_para section_para_pos">
MadFit has helped thousands of users get the results they were looking for. From building strength and mobility to hitting a weight-loss or weight-gain target, we love to celebrate our community’s personal victories.
</div>
<div className="section_inner_feedback section_inner_feedback_pos">
{/* <Carousel axis="vertical" showArrows={true}  showStatus={false}> */}
  
  <Slider {...setting_review}>
<div className=" feedback_0 section_inner_feedback_c   section_inner_feedback_c_pos">
  <div className="section_inner_feedback_star_child section_inner_feedback_star_pos" title="5/5">
<span class="sec_review_star_full  sec_review_star_pos"> 

<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>

</span>
<span class="sec_review_star_full  sec_review_star_pos"> 
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>
</span>
<span class="sec_review_star_full  sec_review_star_pos"> 
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>
</span>
<span class="sec_review_star_full  sec_review_star_pos"> 
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>
</span>
<span class="sec_review_star_full  sec_review_star_pos"> 
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>
</span>
  </div>
  <div className="section_inner_feedback_review_heading  section_com_normal_heading section_inner_feedback_review_heading_pos">
  “THIS APP IS AMAZING”
  </div>
  <div className="section_inner_feedback_review_desc section_inner_feedback_review_desc_pos .section_com_para">
  'My experience with this app is amazing, I learned to workout and appreciate my body and all my efforts.'
  </div>
</div>


<div className=" feedback_1 section_inner_feedback_c card_pos section_inner_feedback_c_pos">
  <div className="section_inner_feedback_star_child section_inner_feedback_star_pos" title="5/5">
<span class="sec_review_star_full  sec_review_star_pos"> 

<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>

</span>
<span class="sec_review_star_full  sec_review_star_pos"> 
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>
</span>
<span class="sec_review_star_full  sec_review_star_pos"> 
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>
</span>
<span class="sec_review_star_full  sec_review_star_pos"> 
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>
</span>
<span class="sec_review_star_full  sec_review_star_pos"> 
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>
</span>
  </div>
  <div className="section_inner_feedback_review_heading  section_com_normal_heading section_inner_feedback_review_heading_pos">
  “THE DIVERSITY OF EXERCISES IS AMAZING!”
  </div>
  <div className="section_inner_feedback_review_desc section_inner_feedback_review_desc_pos .section_com_para">
  'I feel challenged no matter what exercises I do and it gives me results. I love how it works with my time and space at home.' 
  </div>
</div>
</Slider>

<div> 
{/* <div className=" feedback_0 section_inner_feedback_c card_pos   section_inner_feedback_c_pos">
  <div className="section_inner_feedback_star_child section_inner_feedback_star_pos" title="5/5">
<span class="sec_review_star_full  sec_review_star_pos"> 

<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>

</span>
<span class="sec_review_star_full  sec_review_star_pos"> 
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>
</span>
<span class="sec_review_star_empty  sec_review_star_pos"> 
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>
</span>
<span class="sec_review_star_empty  sec_review_star_pos"> 
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>
</span>
<span class="sec_review_star_empty  sec_review_star_pos"> 
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>
</span>
  </div>
  <div className="section_inner_feedback_review_heading  section_com_normal_heading section_inner_feedback_review_heading_pos">
  “Incredible results”
  </div>
  <div className="section_inner_feedback_review_desc section_inner_feedback_review_desc_pos .section_com_para">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
  </div>
</div> */}


{/* <div className=" feedback_1 section_inner_feedback_c card_pos section_inner_feedback_c_pos">
  <div className="section_inner_feedback_star_child section_inner_feedback_star_pos" title="5/5">
<span class="sec_review_star_full  sec_review_star_pos"> 

<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>

</span>
<span class="sec_review_star_full  sec_review_star_pos"> 
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>
</span>
<span class="sec_review_star_empty  sec_review_star_pos"> 
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>
</span>
<span class="sec_review_star_empty  sec_review_star_pos"> 
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>
</span>
<span class="sec_review_star_empty  sec_review_star_pos"> 
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
<path d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z" fill="#FFD600"/>
</svg>
</span>
  </div>
  <div className="section_inner_feedback_review_heading  section_com_normal_heading section_inner_feedback_review_heading_pos">
  “Incredible results”
  </div>
  <div className="section_inner_feedback_review_desc section_inner_feedback_review_desc_pos .section_com_para">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
  </div>
</div> */}

</div>


{/* </Carousel> */}
</div>
<div className="community-btn">
             
             <Link to='/sign-up' class="site-btn take-servey">Join Our Community</Link>
           </div>
  </div>
  </div>
  {/* <div className="col-12 col-md-5 col-lg-5 col-xl-5">
<div className="section_inner_right community-right-carausal">
<Slider {...settings_feedback}>
  <div className="section_right_image">
    <div className="section_right_image_c">
      <img src={require("../assets/images/home/achiev.jpg")} alt="Achievements image" className="section_right_image_c_img" />
    </div>

  </div>
  <div className="section_right_image">
    <div className="section_right_image_c">
      <img src={require("../assets/images/home/achiev.jpg")} alt="Achievements image" className="section_right_image_c_img" />
    </div>

  </div>
    </Slider>
  <div className="section_right_text_area">
    <div className="section_right_header section_com_normal_heading">
    Results
    </div>
    <div className="section_right_para section_com_para section_com_para_pos">
    Check out a small collection of results our users have shared with us. 
    </div>
    
    </div>
    <div className="community-btn">
             
              <Link to='/sign-up' class="site-btn take-servey">Join Our Community</Link>
            </div>

  </div>
  </div> */}
  </div>
        </div>

      </div>
      </div>
</section>



<section class="madfit-sec">
   <div class="container">
      <div class="row">
          <div class="col-lg-6">
             <div class="price-area">
                 <Link to="/sign-up">
                 <div class="row">
                    <div class="col-xl-12 col-lg-12">
                       <div class="price-title-area">
                       <div class="price-title-inner">
                          <h3>1 month</h3>
                          <h4>TRY MADFIT FOR ONLY</h4>
                          <h2>$1.00</h2>
                          <p>For the first month*</p>
                       </div>
                       </div>
                    </div>
                    {/* <div class="col-xl-4 col-lg-12">
                        <div class="price-items price-line">
                           <h4>3 months</h4>
                           <p class="price-dg"><span>$199.94</span>$79.99</p>
                           <p class="save-pr">Save $40</p>
                           <p class="p-month">$13.34 per month </p>
                           <p class="p-month">Billed every 3 months </p>
                        </div>
                    </div> */}

                    {/* <div class="col-xl-4 col-lg-12">
                     <div class="price-items">
                        <h4>12 months</h4>
                        <p class="price-dg"><span>$239.88</span>$119.99</p>
                        <p class="save-pr">Save $120</p>
                        <p class="p-month">$9.99 per month  </p>
                        <p class="p-month">Billed every 3 months </p>
                     </div>
                 </div> */}


                 </div>
          </Link>
             </div>
          
          </div>
          <div class="col-lg-6">
              <div class="madfit-detail">
                 <a className='mf_logo_footer' href="#"><img class="" src={footer_logo} alt="footer logo"/></a>
                 <h2>Try madfit today</h2>
                 <p>Take advantage of this limited deal today and try MadFit out for a whole month for a single dollar.</p> <p> No commitment to any extra subscriptions. <br />Try it out today and cancel at any time during the first month.</p>
                 <Link to="/sign-up" class="site-btn take-servey">Start Now</Link>
              </div>
          </div>
      </div>
   </div>
</section>
<Footer/>


{/* <ScrollToTop/> */}
      
    </>
  )
}

export default Home
