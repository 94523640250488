import React from 'react'

const CustomModalPopup = () => {
  return (
    <>
      
    <p><strong>TERMS OF USE MADFIT APP</strong></p>
<p><strong>WHAT THESE TERMS ARE AND WHAT THEY ARE FOR</strong></p>
<p>
  These terms and conditions (“<strong>Terms” </strong>or
  <strong>“Agreement”</strong>) are applicable when you use our
  mobile software application MadFit (our
  <strong>“App”</strong>).&nbsp;
</p>
<p>
  <strong>The app includes subscriptions that automatically renew</strong>, and
  we are encouraging you to read these carefully (particularly Sections 6:
  Subscription Fees &amp; Payments and 7: Cancellations &amp; Refunds) before
  purchasing a subscription or starting a free or paid trial.&nbsp;
</p>
<p>
  In these Terms, the users of our App are referred to as
  <strong>“User”</strong>, “<strong>you”, “your” </strong>and<strong> “yours”.&nbsp;</strong>
</p>
<p>
  “<strong>We”, “us” </strong>and<strong>
    “our”</strong>
  refer to The Vibe Fitness Ltd, the company owning and operating the App.&nbsp;
</p>
<p>
  At the time you download our App from the app store, you will need to confirm
  that you accept and comply with these Terms. These Terms constitute a legally
  valid and binding agreement between you and us.&nbsp;
</p>
<p>
  Please also refer to our <em>Privacy Policy</em>, which sets out how we deal
  with personal data we collect from you.
</p>
<p>
  These Terms apply to you no matter the device or technique you use to access
  our App. Sometimes other persons may use our App through your device. You are
  responsible for ensuring that all such persons are aware of and comply with
  these Terms.
</p>
<p>
  If you are using our App as a representative of a company or other legal
  entity, you warrant that you are duly authorized by your company to enter into
  this Agreement and grant us any rights under this Agreement on your
  company’s behalf.
</p>
<ol start="1">
  <li>
    <strong><strong>WHO WE ARE AND WHAT WE DO</strong></strong>
  </li>
</ol>
<p>
  MadFit is a trading name of The Vibe Fitness Limited, a private limited
  company registered in New Zealand, with its registered offices at Level 6/135
  Broadway, Newmarket, Auckland 1023.
</p>
<p>
  Our App targets women around the world and encourages them to exercise and
  live a healthy life. It includes exercise videos, information on health and
  nutrition, and a community through which women can exchange information and
  experiences.
</p>
<ol start="2">
  <li>
    <strong><strong>GRANT OF RIGHT TO USE OUR APP</strong></strong>
  </li>
</ol>
<p>
  To let you download, install and use our App, we grant you a non-exclusive,
  non-transferable, non-sublicensable and revocable license, limited to
  download, access and use our App for personal, non-commercial purposes. The
  license is subject to all the terms and conditions of this Agreement. Any
  other use that is not in accordance with this Agreement is strictly prohibited
  without our prior written consent.&nbsp;
</p>
<ol start="3">
  <li>
    <strong><strong>MISUSE OF OUR APP</strong></strong>
  </li>
</ol>
<p>
  If you use our App, please do so with respect. Do not misuse our App or help
  anyone else to do so. You must not knowingly introduce viruses, trojans,
  worms, logic bombs or other material that is malicious or technologically
  harmful. You must not attempt to gain unauthorised access to our App, the
  servers on which our App is stored or any server, computer or database
  connected to it. You must not test the vulnerability of our App or network or
  breach or otherwise circumvent any security or authentication measures we have
  put in place.
</p>
<p>
  It is a criminal offence to breach this provision. Should we find out that you
  breached this provision, we will report this to law enforcement authorities
  and we will cooperate with their investigations. This may include disclosure
  to such authorities of any personal information we hold about you. In addition
  to other remedies we may have in such event, this Agreement with you will be
  terminated immediately which means that your right to use our App ceases with
  immediate effect.&nbsp;
</p>
<ol start="4">
  <li>
    <strong><strong>AGE RESTRICTIONS</strong></strong>
  </li>
</ol>
<p>
  In order to use our App, you must be at least 18 years of age or the legal age
  in your country of residence to be able to enter into legally binding
  agreements. By using our App, you warrant that you are at least 18 years of
  age or such other applicable age in your country of residence.&nbsp;
</p>
<ol start="5">
  <li>
    <strong><strong>ACCOUNT</strong></strong>
  </li>
</ol>
<p>
  There are various ways to start using our App. You can either sign in using
  the Facebook or Instagram log-in feature, or you can create an account with us
  directly using your e-mail address. If you use the latter option, you are only
  allowed to set up one account per User, unless we give you our prior written
  consent to do otherwise. Your account is personal to you, you may not transfer
  or assign your account to someone else.
</p>
<p>
  Within our App you will be able to provide information about yourself in your
  profile. This includes your weight and height. You can also upload photos of
  yourself before and after a work-out.&nbsp;
</p>
<p>
  You warrant that all information you provide us with when you sign up, is
  true, accurate and up-to-date and you agree that it is your responsibility to
  keep it that way at all times.
</p>
<p>
  If you choose, or you are provided with, a user identification code, password
  or any other piece of information as part of our security procedures, you must
  treat such information as confidential. You must not disclose it to anyone
  else. We have the right to disable any user identification code or password,
  whether chosen by you or allocated by us, at any time, if in our reasonable
  opinion you have failed to comply with any of the provisions of these Terms.
  If you know or suspect that anyone other than you knows your user
  identification code or password, you must promptly notify us.&nbsp;
</p>
<ol start="6">
  <li>
    <strong><strong>SUBSCRIPTION FEES AND PAYMENT</strong></strong>
  </li>
</ol>
<p>
  The App can be downloaded free of charge. However, some features may only be
  accessed with a fee either through the app stores or our website.&nbsp;
</p>
<p>
  All subscription fees may change without prior notice. Should you choose to no
  longer avail of a subscription due to fees changes, you may
  <strong>opt to cancel</strong> your subscription
  <strong>at least 24 hours</strong> before your subscription renews.
</p>
<p>
  All subscriptions are automatically renewed, and no refund or pro-rating of
  fees will be granted in case you forgot to cancel your subscription in time.
  Please follow the corresponding instructions by the App Store and Google Play
  in cancelling your subscription.
</p>
<p>
  We may offer a free or lower-priced trial subscription to access the Service
  for a period of time. Unless you cancel your subscription before the trial
  ends, you will be automatically billed for the following month and will have
  access to the Service. We reserve the right to modify or terminate the trial
  offer without any prior notice and with no liability. No multiple trials are
  allowed.
</p>
<p>
  We reserve the right to cancel your subscription at any time after the period
  of payment.
</p>

<p>
  We use a third-party payment processing service provider for the processing of
  all payments made in connection with our App. We are not responsible, nor can
  we be held liable, for any errors or failures in that third-party payment
  provider’s services.&nbsp;
</p>
<p>
  By agreeing to our Terms or continuing to make payments via the app stores,
  our website and the App, you agree to be bound by such terms and conditions,
  as amended from time to time. You agree to provide us with accurate and
  complete information about you, and you authorize us to share any transaction
  information related to your use of the payment processing services provided by
  the third-party payment provider.
</p>

<ol start="7">
  <li>
    <strong><strong>CANCELLATIONS AND REFUNDS</strong></strong>
  </li>
</ol>
<p>
  Cancellations and refunds of the fees for subscriptions purchased through the
  Apple and Google app stores shall be governed by the terms and conditions of
  the corresponding app store.&nbsp;
</p>
<p>
  To avoid getting charged, you need to cancel your subscription or trial
  properly. Please note that cancellations must be done
  <strong>at least 24 hours before</strong> the end of the trial or prior to the
  renewal of your purchased subscription.
</p>
<p>
  Paid or free trials enabled and subscriptions purchased through the Apple
  Appstore or Google Playstore can only be canceled through the account settings
  of the app store where you have enabled a trial or purchased a subscription.
</p>
<p>
  <br/>If you are not sure how to unsubscribe to or cancel a trial or a paid
  subscription, please visit
  <a href="https://support.apple.com/en-us/HT202039">Apple Support</a> or
  <a href="https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&amp;hl=en">Google Play Help</a>. Please note that deleting the app will not cancel your subscriptions and
  free trials. You may want to take a screenshot of this information for
  reference.
</p>
<p>
  <br/>For paid or free trials enabled and subscriptions that are purchased
  through our website, you may contact our support team at least 24 hours before
  the end of the then-current period by emailing team@madfitapp.com or messaging
  us through our social media pages.&nbsp;
</p>
<p>
  <strong>Please note </strong>that such trials and subscriptions cancellations
  may never be managed nor controlled through the Apple Appstore nor the Google
  Playstore. Cancellation will also only be effective from the next billing
  period (week, month, quarter, or year). This means that you will not be
  refunded for the remainder of your then-current subscription in which you
  cancel your subscription. However, you will still have access to all
  subscription features for the remaining time of your then-current
  subscription, and will no longer be charged again once your then-current
  subscription expires.
</p>
<p>
  All purchases on our websites are final upon charge. Once you purchase the
  app, the app is immediately available to you and you will not be able to
  change your mind and receive a refund. You were made aware of this prior to
  ordering and agreed to this when ordering. The Company may provide refunds at
  its own discretion and subject to the existing terms and conditions.
</p>

<ol start="8">
  <li>
    <strong><strong>USER CONTENT&nbsp;</strong></strong>
  </li>
</ol>
<p>
  As a User of our App, you will have the opportunity to participate in our
  community of women around the world. You will be able to ask questions, answer
  other women’s questions and take part in discussions. Please treat other
  Users with respect. Any text, pictures or other material you submit to our
  community together is referred to as
  <strong>“User Content”</strong>. Whenever you submit User Content,
  please make sure that you comply with the following rules:
</p>
<ul>
  <li>the content must be true, accurate, complete and up-to-date;&nbsp;</li>
  <li>
    the content must be your own, or you must have obtained the necessary
    consent from the party whose content you use;&nbsp;
  </li>
  <li>
    the content must not be sexually explicit, pornographic, indecent,
    defamatory, racist, discriminatory, violent or in any other way be illegal
    or unlawful.
  </li>
</ul>
<p>
  You warrant that any User Content you upload complies with the above rules.
  You are liable to us and shall indemnify us and hold us harmless for any
  breach of that warranty. This means you will be solely liable for any losses
  or damages we or anyone else suffers due to User Content that does not comply
  with these rules.
</p>
<p>
  Without prejudice to any other rights and remedies available to us, we have
  the right to remove User Content if, in our reasonable opinion, that User
  Content does not comply with our rules.
</p>
<p>
  Any User Content that you upload or send to or through our App will be
  considered non-confidential and non-proprietary.&nbsp;
</p>
<p>
  Should you submit any User Content in which any intellectual property rights
  exists, you retain all such rights subject to the following license. You grant
  us a perpetual, worldwide, non-exclusive, irrevocable, royalty-free,
  sub-licensable and transferable license to use, store, reproduce, distribute,
  translate, prepare derivative works of, display, edit, adapt, broadcast and
  perform your User Content in connection with our App and the services provided
  via our App and to use your User Content to promote our App, in any media and
  in whatever form. You waive all moral rights that you have or may in the
  future have, in relation to your User Content, insofar as permitted under
  applicable law.&nbsp;
</p>
<p>
  When you upload User Content to our App, you also grant other Users of our App
  and third parties such as our business partners and advertisers, a limited
  right to access, view and store your User Content and use such content for
  their purposes or in accordance with the functionality of our App.
</p>
<p>
  We have the right to disclose your identity to third parties who claim that
  any of your User Content constitutes a violation of their intellectual
  property or other proprietary rights or of their right to privacy.&nbsp;
</p>
<p>You are solely responsible for securing and backing up your User Content.</p>
<ol start="9">
  <li>
    <strong><strong>RIGHTS IN OUR APP AND CONTENT PUBLISHED BY US</strong></strong>
  </li>
</ol>
<p>
  We are the owner or the licensee of all intellectual property rights in and
  attached to our App, and in all content that we make available on it (<strong>“MadFit Content”</strong>). This includes, but is not limited to, copyrights in and attached to texts,
  graphics, designs, logos, photos, videos, graphic user interface, databases
  and software. Those works are protected by copyright laws and treaties around
  the world. All such rights are reserved by us. We also assert our right to be
  identified as the author of all MadFit Content.
</p>
<p>
  Our recipe library is regularly updated through collaboration with other
  recipe developers. Some of our recipes in the Recipe Section of our App are in
  collaboration with
  <a href="https://nourishedbynic.com/about/welcome-to-nourished-by-nic/">NOURISHED BY NIC</a>.
</p>
<p>
  You are not permitted to use our trade name and logo without our prior
  approval. All trade names and trademarks not owned by us that may appear in
  our App are the property of their respective owners, who may or may not be
  affiliated with, connected to, or sponsored by us. Such trademarks are used
  for identification purposes only.
</p>
<p>
  You must not use, copy, modify, distribute, transfer, publicly display,
  publicly perform, broadcast or in any other way exploit our App or any User
  Content or MadFit Content published on it, unless it is your own content. Any
  collection, aggregation, copying, scraping, duplication, display or any
  derivative use of our App as well as the use of data mining, robots, spiders
  or similar data gathering and extraction tools without our prior written
  permission is expressly prohibited. Nothing in these Terms gives you a right
  or license to do so, unless as expressly stated in these Terms.&nbsp;
</p>
<p>
  Our status and that of any identified contributors as the owners of MadFit
  Content must always be acknowledged. Any indications of ownership must not be
  removed or otherwise obscured.&nbsp;
</p>

<ol start="10">
  <li>
    <strong><strong>LIMITATION OF LIABILITY</strong></strong>
  </li>
</ol>
<p>
  We provide our App on an ‘as is’ basis, without warranties of any
  kind, whether express or implied. The use of our App is at your own
  risk.&nbsp;
</p>
<p>
  We do not assume liability for any loss or damage, including liability for
  loss of data, loss of profits, loss of business opportunities, loss of
  goodwill, damages for personal or bodily injury or emotional distress, arising
  out of or connected with these Terms or the use of (or inability to use) our
  App.&nbsp;
</p>
<p>
  It does not matter whether such claims are based on warranties, contract or
  tort, and whether we are informed of the possibility of such damages before
  they are incurred.&nbsp;
</p>
<p>
  Should the above limitation of our liability for any reason be invalid or
  prohibited, in any event our aggregate liability to you shall not exceed the
  total monthly subscription fees paid by you in the 12 months preceding the
  date of the event giving rise to our liability.
</p>
<ol start="11">
  <li>
    <strong><strong>INDEMNIFICATION</strong></strong>
  </li>
</ol>
<p>
  You agree to indemnify us, keep us indemnified and hold us harmless and our
  officers, directors, employees and agents, from and against any claims,
  liabilities, damages (direct, indirect and consequential damages), losses and
  expenses (including reasonable legal fees), arising out of or in connection
  with (a) your breach of these Terms or other terms and policies incorporated
  in it by reference; (b) your improper use of our App; (c) your breach of any
  laws, regulations or third-party rights.&nbsp;
</p>
<ol start="12">
  <li>
    <strong><strong>ERRORS AND VIRUSES</strong></strong>
  </li>
</ol>
<p>
  Although we make reasonable efforts to update the information in our App on a
  regular basis, we make no representations, warranties or guarantees, whether
  express or implied, that the content in our App is always accurate, complete
  or up-to-date.
</p>
<p>
  We do not assume liability for any errors, omissions and inaccuracies in any
  information displayed in our App.&nbsp;
</p>
<p>
  We do not guarantee that our App will be secure or free from bugs or viruses.
</p>
<p>
  You are responsible for configuring your information technology, software and
  platform to access our App. You should use your own virus protection software.
</p>
<ol start="13">
  <li>
    <strong><strong>CHANGES TO OUR APP </strong></strong>
  </li>
</ol>
<p>
  We may update and change our App from time to time. For example to reflect
  changes to our services, our Users’ needs and our business priorities.
  We will try to give Users reasonable notice of any major changes.
</p>
<ul>
  <li>
    <strong><strong>WE MAY SUSPEND OR WITHDRAW OUR APP</strong></strong>
  </li>
</ul>
<p>
  We do not guarantee that our App, or any content on it, will always be
  available or be uninterrupted. We may suspend or withdraw or restrict the
  availability of all or any part of our App for business and operational
  reasons. We will try to give you reasonable notice of any suspension or
  withdrawal.&nbsp;
</p>
<ul>
  <li>
    <strong><strong>WE MAY TERMINATE YOUR ACCESS TO OUR APP</strong></strong>
  </li>
</ul>
<p>
  We may terminate or suspend your account or access to our App without notice
  and at our sole discretion if you breach any provision of these Terms or any
  other applicable law or regulation. We may, at our sole discretion, also take
  additional action, such as involve law enforcement authorities or issue legal
  proceedings against you. We may warn you before we take such action.&nbsp;
</p>
<p>
  Terminating your access to our App may result in the forfeiture and
  destruction of all information associated with you.&nbsp;
</p>
<ol start="14">
  <li><strong> CHANGES TO THIS AGREEMENT</strong></li>
</ol>
<p>
  The App reserves the right to update, revise, supplement and otherwise modify
  this Agreement from time to time. Any such changes to this Agreement will be
  effective immediately for new users, and thirty (30) days after notification
  for current users. Registered users are encouraged to review this Agreement
  periodically for changes. If you do not wish to accept any change, please
  uninstall and discontinue using the App. Your continued use of the App
  following the thirty (30) day notice period constitutes your acceptance of
  those changes.
</p>
<ol start="15">
  <li><strong> TRANSFER OUR RIGHTS AND OBLIGATIONS</strong></li>
</ol>
<p>
  We may transfer our rights and obligations under these Terms to a third party,
  for example as part of a sale of all or part of our assets. We will try to
  give our Users reasonable notice of transfers and we will ensure that such
  transfer will not prejudice your rights and obligations under these
  Terms.&nbsp;
</p>
<p>
  You may not transfer your rights and obligations under these Terms under any
  circumstances without our prior consent. Any such transfer shall be deemed
  null and void.&nbsp;
</p>
<ol start="16">
  <li><strong> DISCLAIMER</strong></li>
</ol>
<p>
  The app only provides information, and not medical or treatment advice and may
  not be treated as such by the user. As such, this app may not be relied upon
  for the purposes of medical diagnosis or as a recommendation for medical care
  or treatment. The information on this app is not a substitute for professional
  medical advice, diagnosis or treatment. All content, including text, graphics,
  images and information, contained on or available through this app is for
  general information purposes only. The information provided by this app is
  provided “as is” without any representations or warranties,
  express or implied. The app makes no representations or warranties in relation
  to any medical or treatment information provided in this app.<br/>
</p>
<ol start="17">
  <li><strong> TERM AND TERMINATION</strong></li>
</ol>
<p>
  These Terms are effective from the date on which you accept these Terms (at
  the time of downloading our App) and remain in full force and effect until
  such time when we terminate this Agreement in accordance with the following
  paragraph, or when you cancel your subscription.&nbsp;
</p>
<p>
  We may terminate this Agreement with you without prior notice if either one of
  the following situations applies:
</p>
<ul>
  <li>
    you have materially breached any of the provisions of these Terms or any of
    the other policies incorporated in these Terms by reference;
  </li>
  <li>
    you have violated any applicable laws, regulations, rules or third-party
    rights;
  </li>
  <li>
    we have reason to believe in good faith that it is necessary to do so in
    order to protect our own interests and safety or those of other Users.
  </li>
</ul>
<p>
  When we terminate this Agreement in either one of the above-mentioned
  situations, we will terminate your access to our App and/or delete your
  account and you will not be entitled to any compensation whatsoever. Your
  existing billed subscription will not be refunded.
</p>
<p>
  When your account has been suspended or deleted, you do not have a right to
  have your account restored or reinstated. You may not register for a new
  account and you should refrain from attempting to use our App via another
  User’s account.
</p>
<ol start="18">
  <li><strong> SEVERABILITY</strong></li>
</ol>
<p>
  The invalidity, illegality or unenforceability of any term (or part of a term)
  of this Agreement shall not affect the continuation in force of the remainder
  of the term (if any) and this Agreement.
</p>
<ol start="19">
  <li><strong> WHICH COUNTRY’S LAWS APPLY TO ANY DISPUTES</strong></li>
</ol>
<p>
  These Terms, their formation and interpretation are construed in accordance
  with and governed by English law.&nbsp;
</p>
<p>
  You and us agree to submit any disputes arising out of or in relation to these
  Terms to the exclusive jurisdiction of the English courts.&nbsp;
</p>
<p>
  As a consumer, you will benefit from any mandatory provisions of the law of
  the country in which you are resident. Nothing in these terms and conditions,
  including this provision, affects your rights as a consumer to rely on such
  mandatory provisions of local law.
</p>
<ol start="20">
  <li><strong> CONTACT</strong></li>
</ol>
<p>
  If you want to send any notice under these Terms or have any questions
  regarding the App, you may contact us at: team@madfitapp.com
</p>
<p>
  I HAVE READ THESE TERMS AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE.
</p>

<p>© {new Date().getFullYear()} Vibe Media Limited</p>
<div id="loom-companion-mv3" ext-id="liecbddmkiiihnedobmlmillhodjkdmb"><section id="shadow-host-companion"></section></div>
    </>
  )
}

export default CustomModalPopup
