import React from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = (props) => {

    const { pathname } = useLocation();
    const kkkk = useLocation();

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        console.log(kkkk,"pasth")
    //   window.scrollTo(0, 0);
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    }, [pathname]);
  
    return <>{props.children}</>

}

export default ScrollToTop
