import React from 'react'

import { useEffect, useState } from 'react'
import Header_Logo from '../assets/images/images/Header_Logo.svg'
import mf_logo_solo from '../assets/images/images/mf_logo_solo.svg'
import app_store from '../assets/images/images/app-store.png'
import google_play from '../assets/images/images/google-play.png'
import { Link } from 'react-router-dom'

const OrderComplete = () => {
  return (
    <>
     <section className="sign_in position_relative orderin-sec" id="container_main_id" >
  <div className="sign_in_bg_left" >
<img src={require("../assets/images/images/full-circle2.png")} alt="" />
  </div>
  <div className="sign_in_bg_right" >
<img src={require("../assets/images/images/full-circle2.png")} alt="" />
  </div>
  <div className="container">

  

  
  {/* <div className="sign_in_bg_right" >
  <img src={require("../assets/images/images/full-circle2.png")} alt="" />
  </div> */}
  <div className="sign_in_inner" >
    <div className="sign_in_inner_content order-sign-content">


           {/* <div id='imageParent' className="loadingParent">
         <div class="lds-ripple"><div></div><div></div></div>
         </div> */}
<div className="logo_div">
  <a href="#">

  <img src={Header_Logo} alt="Madfit logo" className='logo_class' />
</a>
</div>
<span className="Plan-Total-Price" style={{visibility:'hidden'}}>1.00</span>
<div id="sub_monthly" className="sign_in_c2 payment_c2">
  <h1 className='upcase'> order complete!</h1>
  <h3>Thank you so much for joining MadFit!</h3>
  <p>We hope you enjoy your first month with us - check out our recipes, find your favourite workouts in the exercies library and don't forget to sign up to one of the exciting challenges!</p>
  <p>Follow us on social media to keep up to date with all the community buzz and content MadFit offers!</p>
  <ul class="order-social">
    <li><a href="#"><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.6002 6.0949C15.9166 5.31452 15.5399 4.31233 15.5402 3.2749H12.4502V15.6749C12.4263 16.3459 12.143 16.9815 11.6599 17.4478C11.1768 17.9142 10.5316 18.1748 9.86016 18.1749C8.44016 18.1749 7.26016 17.0149 7.26016 15.5749C7.26016 13.8549 8.92016 12.5649 10.6302 13.0949V9.9349C7.18016 9.4749 4.16016 12.1549 4.16016 15.5749C4.16016 18.9049 6.92016 21.2749 9.85016 21.2749C12.9902 21.2749 15.5402 18.7249 15.5402 15.5749V9.2849C16.7932 10.1848 18.2975 10.6675 19.8402 10.6649V7.5749C19.8402 7.5749 17.9602 7.6649 16.6002 6.0949Z" fill="#32BAB0"/>
</svg></a></li>
    <li><a href="#"><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.8 2.2749H16.2C19.4 2.2749 22 4.8749 22 8.0749V16.4749C22 18.0132 21.3889 19.4884 20.3012 20.5761C19.2135 21.6638 17.7383 22.2749 16.2 22.2749H7.8C4.6 22.2749 2 19.6749 2 16.4749V8.0749C2 6.53665 2.61107 5.06139 3.69878 3.97368C4.78649 2.88597 6.26174 2.2749 7.8 2.2749ZM7.6 4.2749C6.64522 4.2749 5.72955 4.65419 5.05442 5.32932C4.37928 6.00445 4 6.92012 4 7.8749V16.6749C4 18.6649 5.61 20.2749 7.6 20.2749H16.4C17.3548 20.2749 18.2705 19.8956 18.9456 19.2205C19.6207 18.5454 20 17.6297 20 16.6749V7.8749C20 5.8849 18.39 4.2749 16.4 4.2749H7.6ZM17.25 5.7749C17.5815 5.7749 17.8995 5.9066 18.1339 6.14102C18.3683 6.37544 18.5 6.69338 18.5 7.0249C18.5 7.35642 18.3683 7.67437 18.1339 7.90879C17.8995 8.14321 17.5815 8.2749 17.25 8.2749C16.9185 8.2749 16.6005 8.14321 16.3661 7.90879C16.1317 7.67437 16 7.35642 16 7.0249C16 6.69338 16.1317 6.37544 16.3661 6.14102C16.6005 5.9066 16.9185 5.7749 17.25 5.7749ZM12 7.2749C13.3261 7.2749 14.5979 7.80169 15.5355 8.73937C16.4732 9.67705 17 10.9488 17 12.2749C17 13.601 16.4732 14.8728 15.5355 15.8104C14.5979 16.7481 13.3261 17.2749 12 17.2749C10.6739 17.2749 9.40215 16.7481 8.46447 15.8104C7.52678 14.8728 7 13.601 7 12.2749C7 10.9488 7.52678 9.67705 8.46447 8.73937C9.40215 7.80169 10.6739 7.2749 12 7.2749ZM12 9.2749C11.2044 9.2749 10.4413 9.59097 9.87868 10.1536C9.31607 10.7162 9 11.4793 9 12.2749C9 13.0706 9.31607 13.8336 9.87868 14.3962C10.4413 14.9588 11.2044 15.2749 12 15.2749C12.7956 15.2749 13.5587 14.9588 14.1213 14.3962C14.6839 13.8336 15 13.0706 15 12.2749C15 11.4793 14.6839 10.7162 14.1213 10.1536C13.5587 9.59097 12.7956 9.2749 12 9.2749Z" fill="#32BAB0"/>
</svg>
</a></li>
    <li><a href="#"><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.5933 7.47791C21.4794 7.05531 21.2568 6.66991 20.9477 6.36008C20.6386 6.05024 20.2537 5.82677 19.8313 5.71191C18.2653 5.28191 12.0003 5.27491 12.0003 5.27491C12.0003 5.27491 5.73633 5.26791 4.16933 5.67891C3.74725 5.79906 3.36315 6.02569 3.0539 6.33704C2.74464 6.6484 2.52062 7.03403 2.40333 7.45691C1.99033 9.02291 1.98633 12.2709 1.98633 12.2709C1.98633 12.2709 1.98233 15.5349 2.39233 17.0849C2.62233 17.9419 3.29733 18.6189 4.15533 18.8499C5.73733 19.2799 11.9853 19.2869 11.9853 19.2869C11.9853 19.2869 18.2503 19.2939 19.8163 18.8839C20.2388 18.7693 20.6241 18.5463 20.934 18.2371C21.2439 17.9279 21.4677 17.5431 21.5833 17.1209C21.9973 15.5559 22.0003 12.3089 22.0003 12.3089C22.0003 12.3089 22.0203 9.04391 21.5933 7.47791ZM9.99633 15.2799L10.0013 9.27991L15.2083 12.2849L9.99633 15.2799Z" fill="#32BAB0"/>
</svg></a></li>
    <li><a href="#"><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 12.2749C22 6.7549 17.52 2.2749 12 2.2749C6.48 2.2749 2 6.7549 2 12.2749C2 17.1149 5.44 21.1449 10 22.0749V15.2749H8V12.2749H10V9.7749C10 7.8449 11.57 6.2749 13.5 6.2749H16V9.2749H14C13.45 9.2749 13 9.7249 13 10.2749V12.2749H16V15.2749H13V22.2249C18.05 21.7249 22 17.4649 22 12.2749Z" fill="#32BAB0"/>
</svg>
</a></li>
  </ul>
  <p class="downloadapp-text">If you haven’t yet downloaded the app, get it on your platform of choice here.</p>
  <ul class="order-downloadapp">
    <li><a href='https://apps.apple.com/app/madfit-home-workout-app/id1577283718' target='blank'> <img src={app_store} alt="app_store logo" /> </a></li>  
    <li><a href='https://play.google.com/store/apps/details?id=nz.co.madfit' target='blank'> <img src={google_play} alt="google_play logo" /> </a></li>
  </ul>
  <p>Just log in with your email and password you created or sign in with your social account.</p>
  <p>We are so glad to have you with us
<strong>Let’s work together to reach our personal goals!</strong></p>
</div>

<Link to="/" className="site-btn sign_in_btn" > 
Let’s Get Started
</Link>

</div>
  </div>
  </div>

</section>
    </>
  )
}

export default OrderComplete
