import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider,GoogleLogin , useGoogleLogin,MyCustomButton} from '@react-oauth/google';
import { FacebookProvider, useLogin } from 'react-facebook';

import {
  HashRouter as Router,
	Route,
	Routes,
	Link 
  } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import ReactGA from "react-ga4";
const root = ReactDOM.createRoot(document.getElementById('root'));

// const baseUrl = document.getElementsByTagName("base") 
// [0].getAttribute("href");
// useEffect(() => {
// 	TagManager.initialize({ gtmId: 'GTM-WLBK3GF' });
//   }, []);
// const tagManagerArgs = {
//     gtmId: 'GTM-WLBK3GF'
// }
 
// TagManager.initialize(tagManagerArgs)
root.render(
  <GoogleOAuthProvider > 
  <FacebookProvider >
  
  <React.StrictMode>
    
    <App />
  </React.StrictMode>
 
  </FacebookProvider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
