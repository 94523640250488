import React from 'react'
import "../assets/css/footer.css"
import $ from 'jquery'
import { Link } from 'react-router-dom'
import CustomModalPopup from './CustomModalPopup'
import PrivacyPolicy from './PrivacyPolicy'
// import ScrollToTop from './ScrollToTop'
const Footer = () => {

  const popupFunction=()=>{
    $(".custom-model-main-terms").addClass('model-open');
    // $(".Click-here").on('click', function() {
    // }); 
  }
  const removePopupFunction=()=>{
    $(".custom-model-main-terms").removeClass('model-open');
  }
  const  popupFunctionPrivacy=()=>{
    $(".custom-model-main-privacy").addClass('model-open');
  }
  const removePopupFunctionPrivacy=()=>{
    console.log('abc')
    $(".custom-model-main-privacy").removeClass('model-open');
    // $(".Click-here").on('click', function() {
    // }); 
  }
  return (
    <div className="footer">
        <div className="footer_inner footer_inner_pos position_relative">
  <div className="footer_inner_c">
    <div className="footer_inner_c_text footer_inner_c_text_pos section_com_para">
    Copyright © Madfit  {new Date().getFullYear()}  {"  "} <span>  | </span> <a onClick={popupFunction}>Terms & conditions </a> - <a onClick={popupFunctionPrivacy}> Privacy policy </a >
    </div>
    {/* <div className="footer_inner_c_text1">

</div> */}
  </div>
        </div>


        <div class="custom-model-main custom-model-main-terms">
        <div class="custom-model-inner">        
        <div class="close-btn" onClick={removePopupFunction}>×</div>
            <div class="custom-model-wrap">
                <div class="pop-up-content-wrap">
                <CustomModalPopup/>
              
                
                   {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam porta sem vitae finibus aliquet. Aliquam erat volutpat. Phasellus ut diam porta quam eleifend lacinia. Duis pretium eros ac consequat venenatis. Quisque pretium blandit odio, non tincidunt lectus maximus ut. Donec lacinia volutpat orci vel egestas. Etiam posuere consequat lectus, et hendrerit arcu dictum vitae. Sed id neque magna. In gravida eros mauris, ac posuere arcu luctus vitae. Ut ultrices est ac vestibulum placerat. Duis ut metus ut nulla dictum tempor.</p> */}
                </div>
            </div>  
        </div>  
        <div class="bg-overlay" onClick={removePopupFunction}></div>
      </div>
      <div class="custom-model-main custom-model-main-privacy">
        <div class="custom-model-inner">        
        <div class="close-btn" onClick={removePopupFunctionPrivacy}>×</div>
            <div class="custom-model-wrap">
                <div class="pop-up-content-wrap">
               
                <PrivacyPolicy/>
                
                   {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam porta sem vitae finibus aliquet. Aliquam erat volutpat. Phasellus ut diam porta quam eleifend lacinia. Duis pretium eros ac consequat venenatis. Quisque pretium blandit odio, non tincidunt lectus maximus ut. Donec lacinia volutpat orci vel egestas. Etiam posuere consequat lectus, et hendrerit arcu dictum vitae. Sed id neque magna. In gravida eros mauris, ac posuere arcu luctus vitae. Ut ultrices est ac vestibulum placerat. Duis ut metus ut nulla dictum tempor.</p> */}
                </div>
            </div>  
        </div>  
        <div class="bg-overlay" onClick={removePopupFunctionPrivacy}></div>
      </div>
      
        {/* <ScrollToTop/> */}
    </div>
  )
}

export default Footer
