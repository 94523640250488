import React from 'react'
import "../assets/css/feature.css"
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import home_circle from '../assets/images/home/circle.svg'

const Feature = () => {
  return (
    <>
  

{/* {console.log("innn")} */}

<Header/>



<section class="challenges-sec">
      <div class="container">
          <div class="section-bg">
              <div class="row">
                  <div class="col-lg-4">
                      <div class="chall-img">
                        <img src={require ("../assets/images/home/terget2.jpg")} alt=""/>
                      </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="chall-cont">
                      <h2 class="upcase">Challenges</h2>
                      <p>Push yourself with our custom challenge programs built from the ground up to help you stay encouraged and target specific fitness goals. <br/> <br/> 
                      Our challenges are more than just a set of exercises - they are built with a focus on the series as a whole, featuring exercises that grow and complement each other and set days for rest and reflection.
                      </p>
                    </div>
                  </div>
              </div>
              
              <div class="row in-app-center">
                  <div class="col-lg-5">
                    <div class="in-app">
                        <h2>In the app</h2>
                        <p>Choose from challenges like the 21 Day Move Challenge, Mindfulness Challenge and more. Let the MadFit app automatically fill out your calendar with a structured routine including rest and reflection days.</p>
                        <Link to="/sign-up" class="site-btn"> Start Now </Link>
                      </div>
                  </div>
                  <div class="col-lg-7">
                  {/* ../assets/images/images/full-circle.png */}
                      <div class="full-circle-area" style={{backgroundImage: `url(${home_circle})`}}>
                        <img src={require ("../assets/images/images/Challenges/challenges_feature.png")} alt=""/>
                      </div>
                  </div>
              </div>

          </div>
      </div>
     
   </section>


   <section class="challenges-sec challenges-reverce">
    <div class="container">
        <div class="section-bg">
            <div class="row">
                <div class="col-lg-4">
                    <div class="chall-img">
                      <img src={require ("../assets/images/home/terget1.jpg")} alt=""/>
                    </div>
                </div>
                <div class="col-lg-8">
                  <div class="chall-cont">
                    <h2 class="upcase">Yoga</h2>
                    <p>Slow down and unwind with our professional yoga classes hosted by MadFit’s own qualified yoga instructor, Arianna. With a large selection of different approaches to more relaxing workouts, MadFit is ready for when you want to look inward and take your exercise routine a little slower.
                    </p>
                  </div>
                </div>
            </div>
            
            <div class="row in-app-center">
                <div class="col-lg-5">
                  <div class="in-app">
                      <h2>In the app</h2>
                      <p>A selection of classes that start with beginners entirely new to the world of yoga, to longer and more advanced flows. Enjoy challenges that mix yoga into your weekly exercise routine.</p>
                      <Link to="/sign-up" class="site-btn"> Start Now </Link>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="full-circle-area" style={{backgroundImage: `url(${require("../assets/images/images/full-circle2.png")})`}}>
                      <img src={require ("../assets/images/images/yoga_feature.png")} alt=""/>
                    </div>
                </div>
            </div>

        </div>
    </div>
 </section>

 <section class="challenges-sec">
      <div class="container">
          <div class="section-bg">
              <div class="row">
                  <div class="col-lg-4">
                      <div class="chall-img">
                        <img src={require ("../assets/images/images/Mindfulness.png")} alt=""/>
                      </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="chall-cont">
                      <h2 class="upcase">Mindfulness</h2>
                      <p>MadFit doesn’t just focus on your physical health - mental health is just as important! To help support your journey in fitness, MadFit offers reflective and meditative classes alongside app features that help you look inward.
                      </p>
                    </div>
                  </div>
              </div>
              
              <div class="row in-app-center">
                  <div class="col-lg-5">
                    <div class="in-app">
                        <h2>In the app</h2>
                        <p>Use the in-app journal function to record your daily thoughts and go back and reflect at any time. Gratitude boxes help bolster positive thoughts and sleep tracking helps identify problems getting in the way of a good night's rest.</p>
                        <Link to="/sign-up" class="site-btn"> Start Now </Link>
                      </div>
                  </div>
                  <div class="col-lg-7">
                      <div class="full-circle-area" style={{backgroundImage: `url(${home_circle})`}}>
                        <img src={require ("../assets/images/images/Mindfulness_feature.png")} alt=""/>
                      </div>
                  </div>
              </div>

          </div>
      </div>
     
   </section>
   <section class="challenges-sec challenges-reverce">
    <div class="container">
        <div class="section-bg">
            <div class="row">
                <div class="col-lg-4">
                    <div class="chall-img">
                      <img src={require ("../assets/images/images/video_classes.png")} alt=""/>
                    </div>
                </div>
                <div class="col-lg-8">
                  <div class="chall-cont">
                    <h2 class="upcase">Video Classes</h2>
                    <p>Choose from a huge library of video classes to guide you through a wide range of different exercises and routines. MadFit offers strength and flexibility training that targets different areas of the body alongside pilates, endurance and yoga classes. With our real-time workouts, you can follow along with your instructor throughout an extended fitness session like a live fitness class.
                    </p>
                  </div>
                </div>
            </div>
            
            <div class="row in-app-center">
                <div class="col-lg-5">
                  <div class="in-app">
                      <h2>In the app</h2>
                      <p>Hours of video content from our expert trainers with individual exercises, real time classes, programs and challenges to choose from.</p>
                      <Link to="/sign-up" class="site-btn"> Start Now </Link>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="full-circle-area" style={{backgroundImage: `url(${require("../assets/images/images/full-circle2.png")})`}}>
                      <img src={require ("../assets/images/images/video_classes_feature.png")} alt=""/>
                    </div>
                </div>
            </div>

        </div>
    </div>
 </section>
 <section class="challenges-sec">
      <div class="container">
          <div class="section-bg">
              <div class="row">
                  <div class="col-lg-4">
                      <div class="chall-img">
                        <img src={require ("../assets/images/images/Nutrition.png")} alt=""/>
                      </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="chall-cont">
                      <h2 class="upcase">Nutrition</h2>
                      <p>With a healthy diet, you’re fueling your body to build muscles, burn calories and get the most out of your workouts. MadFit offers more than one hundred recipes for all meals of the day plus snacks and drinks. Written by our team of nutritionists, our tasty and fueling recipes will keep your energy levels up and ready for your next workout.
                      </p>
                    </div>
                  </div>
              </div>
              
              <div class="row in-app-center">
                  <div class="col-lg-5">
                    <div class="in-app">
                        <h2>In the app</h2>
                        <p>Search through our massive library of recipes with filters for diet type and mealtime. Updated often with new dishes from our team.</p>
                        <Link to="/sign-up" class="site-btn"> Start Now </Link>
                      </div>
                  </div>
                  <div class="col-lg-7">
                      <div class="full-circle-area" style={{backgroundImage: `url(${home_circle})`}}>
                        <img src={require ("../assets/images/images/nutrition_feature.png")} alt=""/>
                      </div>
                  </div>
              </div>

          </div>
      </div>
     
   </section>
 <Footer/>
  
    </>
  )
}

export default Feature
