import React, { useEffect, useState } from "react";
import {
  GoogleOAuthProvider,
  GoogleLogin,
  useGoogleLogin,
  MyCustomButton,
} from "@react-oauth/google";
import Axios from "axios";
import {
  FacebookProvider,
  useLogin,
  LoginButton,
  useProfile,
  status,
  isLoading,
  error,
  useFacebook,
} from "react-facebook";
import "../assets/css/feature.css";
import "../assets/css/signin.css";
// import Header_Logo from '../assets/images/Header_Logo.png'
// import Header_Logo from "../assets/images/images/madfit-logo.svg";
import Header_Logo from "../assets/images/images/Horizontal-Logo-Dark.svg";

// import mf_logo_solo from "../assets/images/images/mf_logo_solo.svg";
// import apple_logo from "../assets/images/images/apple_logo.svg";
import apple_logo from "../assets/images/images/apple.png";

import google_logo from "../assets/images/images/google_logo.svg";
import facebook_logo from "../assets/images/images/facebook_logo.svg";
import back_arrow from "../assets/images/images/arrow-back_1.svg";

import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ERROR_MESSAGE,SUBSCRIBED_MESSAGE } from "../components/planDetails";
import {
  LoginSocialGoogle,
  LoginSocialAmazon,
  LoginSocialFacebook,
  LoginSocialGithub,
  LoginSocialInstagram,
  LoginSocialLinkedin,
  LoginSocialMicrosoft,
  LoginSocialPinterest,
  LoginSocialTwitter,
  LoginSocialApple,
  IResolveParams,
} from "reactjs-social-login";
// import AppleLogin from "react-apple-login";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AppleLogin from "react-apple-login";
import mf_logo_solo from '../assets/images/home/footer-logo-madfit.svg'
import CustomModalPopup from '../components/CustomModalPopup';
import AppleSignin from 'react-apple-signin-auth';
const SignIn = () => {
  // let SUBSCRIBED_MESSAGE="Attention! You already have an active subscription that is valid until [certain date]. There is no need to purchase another subscription at this time. Enjoy uninterrupted access to all our premium features during this period. Thank you for being a valued customer!"
  // const { profile } = useProfile(['id', 'name']);
  let navigate = useNavigate();
  const { init } = useFacebook();

  const { login, status, isLoading, error } = useLogin();
  const [isSignUp, setIsSignUp] = useState(true);
  const [isSignin, setIsSignIn] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [checker, setChecker] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  const [inputValue, setInputValue] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    password: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [formerror, setFormError] = useState({});
  const [signupResponse, setSignupResponse] = useState({});
  const [fbLoginInfo, setFbLoginInfo] = useState({});
  const [googleResponse, setGoogleResponse] = useState({});
  let base_url = process.env.REACT_APP_prod_base_url;

  const changeInput = (e) => {
    const { name, value } = e.target;

    setInputValue({
      ...inputValue,

      [name]: value.trim(),
    });
  };

  const popupFunction = () => {
    $(".custom-model-main").addClass("model-open");
    // $(".Click-here").on('click', function() {
    // });
  };
  const removePopupFunction = () => {
    console.log("abc");
    $(".custom-model-main").removeClass("model-open");
    // $(".Click-here").on('click', function() {
    // });
  };

  async function Fun() {
    const { profile } = useProfile(["id", "name"]);
    console.log(profile, "profile");
  }

  let customConfig = {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  };
  const checkUserSubscription=async(token)=>{
    let checkSubscriptionHeader={...customConfig}
    Object.assign(checkSubscriptionHeader.headers,{Authorization: `Bearer ${token}`})
    try{
   let userSubscriptionResp=  await Axios.get(
      base_url + "api/user/current",
      // JSON.stringify(sign_in_obj),
      // formData
      checkSubscriptionHeader
    )

    let userSubsscriptionData=await userSubscriptionResp ?.data ?.data
    console.log(userSubscriptionResp,userSubsscriptionData,"datta user subscription function")
    return userSubsscriptionData
    }
    catch{
      toast.error(ERROR_MESSAGE, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });

    }
  }
  useEffect(() => {
    // console.log(Object,"object")
    if (Object.keys(formerror).length === 0 && isSubmit) {
      $("#imageParent").css("display", "block");
      // $("#container_main_id").css("-webkit-filter","brightness(50%)")
      console.log(inputValue, "inputValue");
      // const formData = new FormData()
      let formData = {};
      let extractedData = {};
      Object.keys(inputValue).forEach((key) => {
        if (inputValue[key]) {
          extractedData[key] = inputValue[key];
        }
      });
      console.log(extractedData, "extracteddata");

      Object.keys(extractedData).forEach((key) => {
        // if(key=="firstName"){

        //   formData.append(key, inputValue[key])
        // }

        if (key == "emailId") {
          formData["email"] = inputValue[key];
        } else {
          // Object.assign('pa',inputValue[key])
          formData[key] = inputValue[key];
        }
      });

      if (isSignUp) {
        console.log("innn sign up");
        Axios.post(
          base_url + "api/onboard",
          JSON.stringify(formData),
          // formData
          customConfig
        )
          .then(async(response) => {
            console.log(response,"response sign up")


            if (response) {
              console.log(response, "responseee");
              const session = JSON.stringify({
                token: `Bearer ${response.data.access_token}`,
                email: inputValue.emailId,
              });
              window.localStorage.setItem("session", JSON.stringify(session));

              let checkUserSubscriptionData=await checkUserSubscription(response.data.access_token)
              console.log(checkUserSubscriptionData,"")
              $("#imageParent").css("display", "none");
              let subscriptionStatus=checkUserSubscriptionData.subscriptionEnd
              // setIsSignIn(false);
              postSubscritionCheck( subscriptionStatus,response.data.access_token,inputValue.emailId,SUBSCRIBED_MESSAGE,"")
              // if(subscriptionStatus)
              // {
              
              //   if(new Date(subscriptionStatus)>=new Date())
              //   {
              //     toast.info(SUBSCRIBED_MESSAGE+'', {
              //       position: "top-center",
              //       autoClose: 5000,
              //       hideProgressBar: true,
              //       closeOnClick: true,
              //       pauseOnHover: true,
              //       draggable: true,
              //       progress: undefined,
              //       theme: "light",
              //       });
              //       setIsSignUp(true);

              // //     navigate("/payment", {
              // //    state: {
              // //      app_access_token: response.data.access_token,
              // //      email: inputValue.emailId,
              // //      refresh_token: response.data.refresh_token,
              // //    },
              // //  });
              //   }
              //   else{
              
              //     navigate("/payment", {
              //       state: {
              //         app_access_token: response.data.access_token,
              //         email: inputValue.emailId,
              //         refresh_token: response.data.refresh_token,
              //       },
              //     });

              //   }
              //   console.log(checkUserSubscriptionData,"check subsscription")


              // }
              // else{
              //   console.log(checkUserSubscriptionData,"check subsscription")
              //   navigate("/payment", {
              //     state: {
              //       app_access_token: response.data.access_token,
              //       email: inputValue.emailId,
              //       refresh_token: response.data.refresh_token,
              //     },
              //   });
              // }



        
              // setIsSignUp(true);
            }


            
            // setInputValue({
            //   ...inputValue,
            //   firstName: "",
            //   lastName: "",
            //   email: "",
            //   password: "",
            // });
            // $("#imageParent").css("display", "none");


            // setIsSignUp(false);
            // setIsSignIn(true);
          })
          .catch(function (error) {
            $("#imageParent").css("display", "none");
            // $("#container_main_id").css("-webkit-filter","brightness(100%)")
            let errorObj = {};
            let errorData = error.response.data.errors;
            Object.keys(errorData).forEach((key) => {
              errorObj[key] = errorData[key][0];
            });
            setFormError(errorObj);
            console.log(errorData, errorObj);
          });
      } else if (isSignin) {
        let sign_in_obj = {};

        Object.assign(sign_in_obj, {
          client_id: process.env.REACT_APP_client_id,
          username: inputValue.emailId,
          password: inputValue.password,
          scope: process.env.REACT_APP_scope,
          client_secret: process.env.REACT_APP_client_secret,
          grant_type: process.env.REACT_APP_api_type,
        });

        Axios.post(
          base_url + "oauth/token",
          JSON.stringify(sign_in_obj),
          // formData
          customConfig
        )
          .then(async(response) => {
            // $("#imageParent").css("display", "none");

            

            if (response) {
              console.log(response, "responseee");
              const session = JSON.stringify({
                token: `Bearer ${response.data.access_token}`,
                email: inputValue.emailId,
              });
              window.localStorage.setItem("session", JSON.stringify(session));

              let checkUserSubscriptionData=await checkUserSubscription(response.data.access_token)
              console.log(checkUserSubscriptionData,"")
              $("#imageParent").css("display", "none");
              let subscriptionStatus=checkUserSubscriptionData.subscriptionEnd
              setIsSignIn(false);
              postSubscritionCheck( subscriptionStatus,response.data.access_token,inputValue.emailId,SUBSCRIBED_MESSAGE,"")
              // if(subscriptionStatus)
              // {
              
              //   if(new Date(subscriptionStatus)>=new Date())
              //   {
              //     toast.info(SUBSCRIBED_MESSAGE+'', {
              //       position: "top-center",
              //       autoClose: 5000,
              //       hideProgressBar: true,
              //       closeOnClick: true,
              //       pauseOnHover: true,
              //       draggable: true,
              //       progress: undefined,
              //       theme: "light",
              //       });
              //       setIsSignUp(true);

              // //     navigate("/payment", {
              // //    state: {
              // //      app_access_token: response.data.access_token,
              // //      email: inputValue.emailId,
              // //      refresh_token: response.data.refresh_token,
              // //    },
              // //  });
              //   }
              //   else{
              
              //     navigate("/payment", {
              //       state: {
              //         app_access_token: response.data.access_token,
              //         email: inputValue.emailId,
              //         refresh_token: response.data.refresh_token,
              //       },
              //     });

              //   }
              //   console.log(checkUserSubscriptionData,"check subsscription")


              // }
              // else{
              //   console.log(checkUserSubscriptionData,"check subsscription")
              //   navigate("/payment", {
              //     state: {
              //       app_access_token: response.data.access_token,
              //       email: inputValue.emailId,
              //       refresh_token: response.data.refresh_token,
              //     },
              //   });
              // }



        
              setIsSignUp(true);
            }
          })
          .catch(function (error) {
            $("#imageParent").css("display", "none");

            console.log(error, "errorr");
            let errorObj = {};
            console.log(
              error?.response?.data?.success,
              typeof error?.response?.data?.success,
              "lolll"
            );
            // let errorData = error.response.data.message;
            if (!error?.response?.data?.success) {
              Object.assign(errorObj, {
                password: "The user credentials were incorrect.",
              });
            }
            setFormError(errorObj);

            console.log(error, "errrrrrrrrrrrrrrrrr");
          });
      } else if (isForgotPassword) {
        console.log("forgetpassword");
        let forgot_password_obj = {};

        Object.assign(forgot_password_obj, { email: inputValue.emailId });

        Axios.post(
          base_url + "api/password/token",
          JSON.stringify(forgot_password_obj),
          // formData
          customConfig
        )
          .then((response) => {
            $("#imageParent").css("display", "none");

            setInputValue({
              ...inputValue,
              firstName: "",
              lastName: "",
              email: "",
              password: "",
            });
            setIsSignUp(false);

            if (response) {
              $("#password_reset_state").css("display", "block");
              setTimeout(() => {
                $("#password_reset_state").css("display", "none");
                setIsForgotPassword(false);
                setIsSignIn(true);
              }, [5000]);
            }
          })
          .catch(function (error) {
            $("#imageParent").css("display", "none");
            // $("#container_main_id").css("-webkit-filter","brightness(100%)")
            console.log(error, "errorr");
            let errorObj = {};
            let errorData = error.response.data.message;
            Object.assign(errorObj, { email: errorData });
            setFormError(errorObj);

            console.log(error, "errrrrrrrrrrrrrrrrr");
          });
      }
    }
    if (checker) {
      setInputValue({
        ...inputValue,
        firstName: "",
        lastName: "",
        emailId: "",
        password: "",
      });
      setChecker(false);
    }
    setIsSubmit(false);
  }, [formerror]);

  const submitThis = (e) => {
    e.preventDefault();

    setFormError(validate(inputValue));

    setIsSubmit(true);
  };
  const validate = (values) => {
    const error = {};
    const email_regx = /^\w+([\+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!inputValue.emailId) {
      error.email = "Please provide an email";
    } else {
      //    const regxPhone = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

      if (!email_regx.test(values.emailId)) {
        error.email = "Please provide a valid email";
      }
    }

    if (!isForgotPassword) {
      if (!inputValue.password) {
        error.password = "Please provide a password";
      } else if (inputValue.password.length < 6) {
        error.password = " The password must be at least 6 characters";
      }
    }

    if (isSignUp && !inputValue.firstName) {
      error.firstName = "Please provide a first name";
    }

    return error;
  };
  const appleLoginPopup = () => {};
  const facebookLoginPopup = () => {};
  const googleLoginPopup = () => {};
  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };
  const backMenu = () => {
    navigate(-1);
  };
let dateFormatingFun=(dateFormat)=>{
  let extractedDate=dateFormat.toDateString()
  let weekDay=extractedDate.split(" ")[0]
  let finalDate=extractedDate.replace(`${weekDay}`,"").trim()
  let findLastSpace= finalDate.lastIndexOf(" ")
  let startDate=finalDate.substr(0,findLastSpace); 
  let lastDate=finalDate.substr(findLastSpace+1);
  let finalDateString=startDate+','+lastDate;
  return finalDateString
}
const postSubscritionCheck=(subscriptionStatus,app_access_token,email,message,offset)=>{
  if(subscriptionStatus)
  {
    let dateFormat=new Date(subscriptionStatus*1000)
    let finalDateString=dateFormatingFun(dateFormat)
  //  let extractedDate=dateFormat.toDateString()
  //  let weekDay=extractedDate.split(" ")[0]
  //  let finalDate=extractedDate.replace(`${weekDay}`,"").trim()
  //  let findLastSpace= finalDate.lastIndexOf(" ")
  //  let startDate=finalDate.substr(0,findLastSpace); 
  //  let lastDate=finalDate.substr(findLastSpace+1);
  //  let finalDateString=startDate+','+lastDate

    // .toDateString()
    if(dateFormat>=new Date())
    {
      toast.info(`Attention! You already have an active subscription that is valid until ${finalDateString}. There is no need to purchase another subscription at this time. Enjoy uninterrupted access to all our premium features during this period. Thank you for being a valued customer!`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
        // setIsSignUp(true);

  //     navigate("/payment", {
  //    state: {
  //      app_access_token: response.data.access_token,
  //      email: inputValue.emailId,
  //      refresh_token: response.data.refresh_token,
  //    },
  //  });
    }
    else{
      // toast.info(SUBSCRIBED_MESSAGE+'', {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
      //   setIsSignUp(true);
      console.log(subscriptionStatus,"in less than date subscription status  ")
      navigate("/payment", {
        state: {
          app_access_token: app_access_token,
          email: email,
          // refresh_token: response.data.refresh_token,
        },
      });

    }
    console.log(subscriptionStatus,"in if subscription status  ")
  }
  else{
    console.log(subscriptionStatus,"check subsscription else null  check")
    navigate("/payment", {
      state: {
        app_access_token: app_access_token,
        email: email,
        // refresh_token: response.data.refresh_token,
      },
    });
  }
}

  const signupAuth=async(code,appleObject,provider)=>{
    
    // if (appleObject.lastName=="unknown")
    let ssoObject={}
    Object.assign(ssoObject,{socialAccessToken:code})
    Object.keys(appleObject).forEach((key)=>{
ssoObject[key]=appleObject[key]

    })
    // let ssoObject={accessToken:code,provider:'apple',  }
    try{
      let authResponse= Axios.post(
           base_url + "api/onboard",
           JSON.stringify(ssoObject),
           // formData
         customConfig
         )
        let response= await authResponse
        console.log(response,"responseee sign up auth")
        let ifSigninApiResponse =await ifSigninApi(code,{},appleObject.provider)
        console.log(ifSigninApiResponse,"ifSigninresponsee in auth")
        let user_access_token=await ifSigninApiResponse.data.access_token
     
 console.log(user_access_token,"user access in reg_scocial_auth")

        let checkUserSubscriptionData=await checkUserSubscription(user_access_token)
        console.log(checkUserSubscriptionData,"subscriptioncheckk")
        $("#imageParent").css("display", "none");
        let subscriptionStatus=checkUserSubscriptionData.subscriptionEnd
        // setIsSignIn(false);
       postSubscritionCheck(subscriptionStatus,user_access_token,appleObject.email,SUBSCRIBED_MESSAGE,"")
        // if(subscriptionStatus)
        // {
        //   if(new Date(subscriptionStatus)>=new Date())
        //   {
        //     toast.info(SUBSCRIBED_MESSAGE+'', {
        //       position: "top-center",
        //       autoClose: 5000,
        //       hideProgressBar: true,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //       theme: "light",
        //       });

        //   }
        //   else{

        //     navigate("/payment", {
        //       state: {
        //         app_access_token: user_access_token,
        //         email: appleObject.email,
               
        //       },
        //     });

        //   }
        //   console.log(checkUserSubscriptionData,"check subsscription")
        // }
        // else{
        //   console.log(checkUserSubscriptionData,"check subsscription")
        //   navigate("/payment", {
        //     state: {
        //       app_access_token: user_access_token,
        //       email: appleObject.email,
             
        //     },
        //   });
        // }
        
  
       }
       catch(err){
        console.log(err,"errrr")
        $("#imageParent").css("display", "none");
      toast.error("Authentication failed. To continue authenticate yourself again", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });



       }



  }
  let ifSigninApi=async(id_token,authObject,provider)=>{
    let code =id_token
    let ssoObject={accessToken:code,provider:provider }
    let return_obj={}
    try{
    let authResponse= Axios.post(
         base_url + "api/social/auth",
         JSON.stringify(ssoObject),
         // formData
       customConfig
       )
      let response= await authResponse
      console.log(response,"responseee")
      return_obj={...response}
      // return response

     }
     catch(err){
      console.log(err,err.response.data.success,"ffinding the error of responseee")
      let successStatus=err?.response?.data?.success 


    
      let email =authObject?.user?.email || ""
      let firstName=authObject?.user?.name?.firstName || ""
      let lastName=authObject?.user?.name ?.lastName || ""
      let appleObject={email:email,firstName:firstName,lastName:lastName,socialProvider:provider}

     
      if (successStatus==false)
      {
console.log(successStatus,"successststus")
        await signupAuth(code,appleObject,provider)
      }


     }

return return_obj
  }
  let callAppleLogin=async(appleObject)=>{
    $("#imageParent").css("display", "block");
    console.log(appleObject,"eeee");
   let ifSigninApiResponse= await ifSigninApi(appleObject ?.authorization ?.id_token,appleObject,"apple")
   console.log(ifSigninApiResponse, "if sign in successful without regs")
   if(Object.keys(ifSigninApiResponse).length>0)
   {
  let user_access_token= await ifSigninApiResponse.data.access_token
  console.log(user_access_token,"useraccesstoken for sign in")

   let checkUserSubscriptionData=await checkUserSubscription(user_access_token)
        console.log(checkUserSubscriptionData,"if sign in successful subscription status")
        $("#imageParent").css("display", "none");
        let subscriptionStatus=checkUserSubscriptionData.subscriptionEnd
        // setIsSignIn(false);
        if(subscriptionStatus)
        {
          let dateFormat=new Date(subscriptionStatus*1000)
          let finalDateString=dateFormatingFun(dateFormat)
          // if(new Date(subscriptionStatus*1000)>=new Date())
          if(dateFormat>=new Date())
          {
            toast.info(`Attention! You already have an active subscription that is valid until ${finalDateString}. There is no need to purchase another subscription at this time. Enjoy uninterrupted access to all our premium features during this period. Thank you for being a valued customer!`, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
              // setIsSignUp(true);

        //     navigate("/payment", {
        //    state: {
        //      app_access_token: response.data.access_token,
        //      email: inputValue.emailId,
        //      refresh_token: response.data.refresh_token,
        //    },
        //  });
          }
          else{
            // toast.info(SUBSCRIBED_MESSAGE+'', {
            //   position: "top-right",
            //   autoClose: 5000,
            //   hideProgressBar: true,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: "light",
            //   });
            //   setIsSignUp(true);
            navigate("/payment", {
              state: {
                app_access_token: user_access_token,
                email: null,
               
              },
            });

          }
          console.log(checkUserSubscriptionData,"check subsscription in successfil sign in if date")
        }
        else{
          console.log(checkUserSubscriptionData,"check subsscription in successfil sign in payment")
          navigate("/payment", {
            state: {
              app_access_token: user_access_token,
              email: null,
             
            },
          });
        }
      }
   
    // Axios.post(
    //   base_url + "oauth/token",
    //   JSON.stringify(sign_in_obj),
    //   // formData
    //   customConfig
    // )
    //   .then(async(response) => {
  

  }

  return (
    <>
    <ToastContainer/>
      <div id="imageParent" className="loadingParent">
        <div class="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>

      {console.log("profileee")}

      <section className="sign_in position_relative " id="container_main_id">
        <div className="back_btn_info">
          <button class="" onClick={() => backMenu()}>
            <img src={back_arrow} alt="" />
            {/* <span></span> */}
          </button>
        </div>
        <div className="sign_in_bg_left">
          <img
            src={require("../assets/images/images/full-circle2.png")}
            alt=""
          />
        </div>
        <div className="sign_in_bg_right">
          <img
            src={require("../assets/images/images/full-circle2.png")}
            alt=""
          />
        </div>
        <div className="container">
          <div className="sign_in_inner">
            <div className="logo_div">
              <a href="#">
                <img
                  src={Header_Logo}
                  alt="Madfit logo"
                  className="logo_class"
                />
              </a>
            </div>
            <div className="sign_in_inner_content">
              <div className="sign_in_c1">
                <h2 className="step_01">
                  <span> Step 1 - Sign in .</span> Step 2 - Confirm and pay
                </h2>
              </div>
              <div className="sign_in_c2">
                <h1 className="upcase"> Sign up</h1>

                <p>
                  It only takes a few steps to get{" "}
                  <strong>
                    instant access to everything the MadFit app has to offer.
                  </strong>{" "}
                </p>
              </div>

              <div className="sign_in_options">
               
              {/* <AppleLogin
    clientId={""+process.env.REACT_APP_APPLE_ID}
    redirectURI="https://summer.maddielymburner.co/sign-up"
    // usePopup={true}
    callback={(e)=>console.log(e,"apple sso")} 
    scope="email name"
    responseMode="query"
    render={renderProps => (  
    <button className='site-btn sso_btn apple_sso tooltips fades'  data-title="Work in progress" onClick={renderProps.onClick} > <img src={apple_logo} alt="Apple_logo" /> Sign in with Apple  </button>
                )}
 
 /> */}
 {/* <AppleLogin
    clientId={""+process.env.REACT_APP_APPLE_ID}
    redirectURI="https://summer.maddielymburner.co/sign-up"
    // usePopup={true}
    callback={(e)=>{console.log(e,"appleSSO");callAppleLogin(e)}} 
    scope="email name"
    responseMode="query"
  


    render={renderProps => ( 
    <button className='site-btn sso_btn apple_sso tooltips fades'  data-title="Work in progress" onClick={renderProps.onClick} > <img src={apple_logo} alt="Apple_logo" /> Sign in with Apple  </button>
                )}
  /> */}



                {/* <p>Login with social account</p> */}
                {/* <div className="sign_in_options_btn"> */}
                {/* <span class="tooltip fade" data-title="Hypertext Markup Language">HTML</span> */}

                {/* <button className='site-btn sso_btn apple_sso tooltips fades' disabled data-title="Work in progress" onClick={() => appleLoginPopup()} > <img src={apple_logo} alt="Apple_logo" /> Sign in with Apple  </button> */}
                {isSignUp || isForgotPassword ? (
                  <a
                    className="site-btn sign_in_btn"
                    onClick={() => {
                      setFormError({});
                      setIsSignUp(false);
                      setIsSignIn(true);
                      setIsForgotPassword(false);
                      setChecker(true);
                    }}
                  >
                    <img src={mf_logo_solo} alt="Medfit solo lofo" />
                    Continue with Madfit
                  </a>
                ) : (
                  <a
                    className="site-btn sign_in_btn"
                    onClick={() => {
                      setFormError({});
                      setIsSignIn(false);
                      setIsSignUp(true);
                      setIsForgotPassword(false);
                      setChecker(true);
                    }}
                  >
                    <img src={mf_logo_solo} alt="Medfit solo lofo" />
                    Create a Madfit account
                  </a>
                )}
                <p className="alert_pop" id="sign_alert">
                  Sign in successful !{" "}
                </p>
                {isSignUp && (
                  <p class="create-account">
                    {" "}
                    <h5> or create a new account </h5>
                  </p>
                )}

                <p className="alert_pop" id="reg_alert">
                  Registration successful !{" "}
                </p>
              </div>
              {(isSignUp || isSignin || isForgotPassword) && (
                <div className="form_section">
                  {console.log(isSignUp, "is sign uppp")}
                  <p
                    className="alert_pop password_reset_status"
                    id="password_reset_state"
                  >
                    Password reset link has been sent to your mail{" "}
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 130.2 130.2"
                    >
                      <circle
                        class="path circle"
                        fill="none"
                        stroke="#004085"
                        stroke-width="15"
                        stroke-miterlimit="10"
                        cx="65.1"
                        cy="65.1"
                        r="62.1"
                      />
                      <polyline
                        class="path check"
                        fill="none"
                        stroke="#004085"
                        stroke-width="15"
                        stroke-linecap="round"
                        stroke-miterlimit="10"
                        points="100.2,40.2 51.5,88.8 29.8,67.5 "
                      />
                    </svg>{" "}
                  </p>
                  <form className="sign_up_form" onSubmit={submitThis}>
                    {isSignUp && (
                      <div>
                        <div className="position_relative form-group input_parent">
                          <input
                            type="text"
                            id="first_name"
                            placeholder="First Name"
                            className="fullInput"
                            name="firstName"
                            value={inputValue.firstName}
                            onChange={changeInput}
                          />

                          {Object.keys(formerror).length > 0 && (
                            <div className="formerror">
                              {<p>{formerror.firstName} </p>}
                            </div>
                          )}
                        </div>

                        <div className="position_relative form-group input_parent">
                          <input
                            type="text"
                            id="last_name"
                            placeholder="Last Name"
                            className="fullInput"
                            name="lastName"
                            value={inputValue.lastName}
                            onChange={changeInput}
                          />

                          {Object.keys(formerror).length > 0 && (
                            <div className="formerror">
                              {<p>{formerror.lastName} </p>}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="position_relative form-group input_parent">
                      <input
                        type="text"
                        placeholder="Email"
                        id="emailId"
                        className="fullInput"
                        name="emailId"
                        value={inputValue.emailId}
                        onChange={changeInput}
                      />

                      {Object.keys(formerror).length > 0 && (
                        <div className="formerror">
                          {<p>{formerror.email} </p>}
                        </div>
                      )}
                    </div>

                    {!isForgotPassword && (
                      <div className=" position_relative flexBox-inner form-group input_parent">
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder=" Password"
                          id="password"
                          className="fullInput"
                          name="password"
                          value={inputValue.password}
                          onChange={changeInput}
                        />
                        {showPassword ? (
                          <img
                            class="view_icon"
                            onClick={handleClickShowPassword}
                            src={require("../assets/images/images/view_hide.png")}
                            alt="hide view icon"
                          />
                        ) : (
                          // <img
                          //   class="view_icon"
                          //   onClick={handleClickShowPassword}
                          //   src={require("../assets/images/images/view.png")}
                          //   alt="view icon"
                          // />
                          <img
                            class="view_icon"
                            onClick={handleClickShowPassword}
                            src={require("../assets/images/images/view.png")}
                            alt="view icon"
                          />
                        )}

                        {Object.keys(formerror).length > 0 && (
                          <div className="formerror">
                            {<p>{formerror.password} </p>}
                          </div>
                        )}
                      </div>
                    )}
                    {/* } */}
                    <div className="sign_in_btn_pos">
                      {isSignUp && (
                        <button
                          className="site-btn sign_in_btn"
                          id="searchBtn"
                          type="submit"
                        >
                          Continue
                        </button>
                      )}
                      {isForgotPassword && (
                        <button
                          className="site-btn sign_in_btn"
                          id="searchBtn"
                          type="submit"
                        >
                          Continue
                        </button>
                      )}
                      {console.log(isSignin, "is sign in")}
                      {isSignin && (
                        <div>
                          <button
                            className="site-btn sign_in_btn"
                            id="searchBtn"
                            type="submit"
                          >
                            Continue
                          </button>
                          <a
                            onClick={() => {
                              setChecker(true);
                              setFormError({});
                              setIsSignUp(false);
                              setIsForgotPassword(true);
                              setIsSignIn(false);
                            }}
                            className="forget_password"
                          >
                            {" "}
                            Forgot password ?
                          </a>
                        </div>
                      )}
                    </div>
                  </form>
                  <p class="btpara">
                    This offer is for new customers only. By signing up, you
                    agree to our{" "}
                    <a onClick={popupFunction}>Terms and conditions.</a> You
                    will be billed $1.00 USD immediately and will be charged
                    $19.99 USD to the same payment details unless you
                    unsubscribe.{" "}
                    <strong>
                      {" "}
                      You may unsubscribe from membership at any time and will
                      not be required to pay anything further.
                    </strong>{" "}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <div class="custom-model-main">
        <div class="custom-model-inner">
          <div class="close-btn" onClick={removePopupFunction}>
            ×
          </div>
          <div class="custom-model-wrap">
            <div class="pop-up-content-wrap">
             < CustomModalPopup/>
              {/* <iframe
                src="https://maddielymburner.co/terms.html"
                frameborder="0"
              ></iframe> */}
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam porta sem vitae finibus aliquet. Aliquam erat volutpat. Phasellus ut diam porta quam eleifend lacinia. Duis pretium eros ac consequat venenatis. Quisque pretium blandit odio, non tincidunt lectus maximus ut. Donec lacinia volutpat orci vel egestas. Etiam posuere consequat lectus, et hendrerit arcu dictum vitae. Sed id neque magna. In gravida eros mauris, ac posuere arcu luctus vitae. Ut ultrices est ac vestibulum placerat. Duis ut metus ut nulla dictum tempor.</p> */}
           
           
           
            </div>
          </div>
        </div>
        <div class="bg-overlay" onClick={removePopupFunction}></div>
      </div>
    </>
  );
};

export default SignIn;
