import React,{useEffect} from 'react';
import './assets/css/main.css';
import './assets/css/feature.css';
// import Home from './pages/Home';
import MainLanding from './pages/MainLanding';
// import Survey_ans from './pages/Survey_ans';
import SignIn from './pages/SignIn';
import Main from './pages/Main';
import Feature from './pages/Feature';
import Payment from './pages/Payment';
import Signinnew from './pages/SigninNew'
import OrderComplete from './pages/OrderComplete';
import Trainer from './pages/Trainer';
import Survey from './pages/Survey';
import TagManager from 'react-gtm-module'
import ScrollToTop from './components/ScrollToTop';
import {
  BrowserRouter as Router,
	Route,
	Routes,
	Link 
  } from "react-router-dom";

  const tagManagerArgs = {
    gtmId: 'GTM-NB68536',
  }

// const rootElement = document.getElementById("root");
// import { useEffect } from 'react';
  // import Trainer from './pages/Trainer';

function App() {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  }, [])
 
  return (

    <Router >
      <ScrollToTop>
   
    <Routes>
    {/* <Route path='/' element ={<MainLanding />}/> */}
    <Route path='/' element ={<Main />}/>
    <Route path='/home' element ={<Main />}/>
    <Route path='/sign-up' element ={<SignIn />}/>
    {/* <Route path='/home' element ={<Main />}/> */}
  <Route path='/payment' element ={<Payment />}/>
  <Route path='/sign_new' element ={<Signinnew />}/>
  <Route path='/order-complete' element ={<OrderComplete />}/>
  <Route path='/feature' element ={<Feature />}/>
  <Route path='/trainer' element ={<Trainer />}/>
  <Route path='/survey-ans' element ={<Survey />}/>
  

  



    {/* <Route path='/home' element ={<Home />}/>
    <Route path='/survey_anser' element ={<Survey_ans />}/>
    <Route path='/feature' element ={<Feature />}/>
  <Route path='/payment' element ={<Payment />}/> */}



    {/* <MainLanding/> */}
    {/* <Landing/> */}
    
{/* <Home/> */}

</Routes>
</ScrollToTop>
    </Router>
  );
}

export default App;