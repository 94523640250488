import React, { useEffect } from 'react'
// import Header_Logo from '../assets/images/images/madfit-logo.svg'
import Header_Logo from '../assets/images/images/Horizontal-Logo-Dark.svg'

import '../assets/css/header.css'
import $ from 'jquery'
import { Link } from 'react-router-dom';
import { useLocation,useNavigate } from "react-router-dom";
import back_arrow from '../assets/images/images/arrow-back_1.svg'



const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let splitLocation=""

  const { pathname } = location;
  // if ((pathname.split("/")[1] === "trainer") ||(pathname.split("/")[1] === "feature"))
  // {
    console.group("innn")
  // $(window).scroll(function() {
  //   console.log($(this),"koljara")
  //   if ((pathname.split("/")[1] === "trainer") ||(pathname.split("/")[1] === "feature")){
  //   if ($(this).scrollTop() > 50){  
  //       $('.mobile-header-arrow').addClass("mobile-header-arrow-active");
  //   }
  //   else {
  //       $('.mobile-header-arrow').removeClass("mobile-header-arrow-active");
  //   }
  // }
  // else{
  //   $('.mobile-header-arrow').removeClass("mobile-header-arrow-active");
  // }
  //   });
  // }
  // else{
  //   $('.mobile-header-arrow').removeClass("mobile-header-arrow-active");
  // }
  useEffect(()=>{
    console.log(pathname,location,"pathnamee");
  
    //Javascript split method to get the name of the path in array
 splitLocation = pathname.split("/")[1];
//  console.log(splitLocation,"kklop")
  
  },[])

    const toggleMenu=(e)=>{
        // $(".toggle-menu").toggleClass('active');
        // $('#menu').toggleClass('open');


        // const navigate = useNavigate();
        navigate(-1);
    }
    const onLinkClick=(e)=>{
      // e.currentTarget.classList.contains("active")
// console.log("hhh", e.,e.target.id)

    }
  return (
    <>
    <div className="container-header desktop_header">

    <div className="container_header_offer">
      <div className="container_header_offer_text offer_text_margin_setter section_com_semi_bold">
      Try a month of MADFIT for only $1.00
      </div>
      </div>  

    <div className="container">
<div className='header '>

<div className="logo_part">
    <Link to="/" id="Nav-logo">
    <div className="image_logo_parent">
        <img src={Header_Logo} alt="Madfit logo" className="image_logo" />


        
    </div>
   </Link>


</div>
<ul className="text_part">
  {console.log(splitLocation,"kkk")}
{/* className="left_text_part text_detail section_com_normal_heading" */}
    <li className={pathname.split("/")[1] === "trainer" ? "left_text_part text_detail section_com_normal_heading active_nav" : "left_text_part text_detail section_com_normal_heading"}  >
      <Link  id="trainer" onClick={onLinkClick} to='/trainer'>

    Trainer Biographies
      </Link>
    </li>
    <li className={pathname.split("/")[1] === "feature" ? "right_text_part text_detail section_com_normal_heading active_nav" : "right_text_part text_detail section_com_normal_heading"} >
      <Link id='feature' onClick={onLinkClick} to="/feature">

    App Feature
      </Link>
    </li>
</ul>
    </div>
</div>
    </div>
    <section class="mobile-header">
   <header id="header">
      <nav class="nav" >
        <Link to="/"><img src={Header_Logo} alt="Header logo"/></Link>
        {/* {
        
        (pathname.split("/")[1] == "trainer" ||  pathname.split("/")[1] == "feature") &&
        <button class="toggle-menu" onClick={() => toggleMenu()} >
          <img src={back_arrow} alt="" />
        
        </button>
} */}
      </nav>
    
      {/* className={pathname.split("/")[1] === "feature" ? "active_nav" : ""} */}
    <div id="menu" class="">
      <nav class="main-nav">
        <ul>
          <li>
            <Link className={pathname.split("/")[1] === "trainer" ? "active_nav" : ""} to="/trainer">
               Trainer Biographies
            </Link>
          </li>
          <li>
            <Link className={pathname.split("/")[1] === "feature" ? "active_nav" : ""} to="/feature">
               App Feature
            </Link>
          </li>
          
        </ul>
      </nav>

    </div>
   </header>
</section>
<section className="mobile-header-arrow">
{
        
        (pathname.split("/")[1] == "trainer" ||  pathname.split("/")[1] == "feature") &&
        <a class="" onClick={() => toggleMenu()} >
          <img src={back_arrow} alt="" />
          {/* <span></span> */}
        </a>
}
</section>
      
    </>
  )
}

export default Header
