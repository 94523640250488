import React from 'react'

const CustomModalPopup = () => {
  return (
    <>
      
      <h1>PRIVACY POLICY MADFIT APP</h1>
<p><strong>INTRODUCTION</strong></p>
<p>
This mobile software application (App) is brought to you by Vibe Media Limited
(“we”, “us” and “our”).
</p>
<p>
We take your privacy very seriously and we are committed to protect your
personal data and respect your privacy. Personal data means all information
about you which enables us to identify you as an individual. We comply with
all relevant data protection laws, such as the General Data Protection
Regulation (GDPR: EU Regulation 2016/679). We ask that you read this privacy
policy (“Policy”) carefully as it contains important information
on who we are, how and why we collect, store, use and share personal
information, your rights in relation to your personal information and on how
to contact us and supervisory authorities in the event you have a complaint.
</p>
<p>
Any capitalized terms used throughout this Policy that are defined in our
<em>Terms of Use</em>, have the same meaning in this Policy.
</p>
<p><strong>DATA CONTROLLER</strong></p>
<p>
Vibe Media Limited is your data controller. This means that we are responsible
for, and control the collecting and processing of, your personal data. You can
find all our details under <em>How to contact us.</em>
</p>
<p><strong>PERSONAL DATA WE PROCESS</strong></p>
<p>
Whenever you use our App, you will provide us with and we will obtain from
you, either directly or indirectly, certain personal data. For example when
you sign up for an account, when you fill in forms provided by us, when you
purchase products or services through our App, when you participate in
competitions, when you participate in our community, and when you communicate
with us.
</p>
<p>
Please note that you can always monitor and control the processing of your
personal data by exercising <em>Your rights</em>.
</p>
<p>
It depends on the particular activities carried out by you through our App
which of the following personal data we collect and process from you:
</p>
<ul>
<li>
<strong><strong>name, home address, e-mail address, telephone number;</strong></strong>
</li>
<li><strong>nationality, place of birth, date of birth, gender;</strong></li>
<li>
<strong>your account details, such as your username and login details;</strong>
</li>
<li><strong>credit/debit card or other payment information;</strong></li>
<li>
<strong>information about your purchases of products and services from us or our
partners;</strong>
</li>
<li>
<strong>location data, including real-time geographic location of your electronic
device, used through GPS;</strong>
</li>
<li><strong>IP address;</strong></li>
<li>
<strong>any other personal data you provide us with voluntarily.</strong>
</li>
</ul>
<p>
We also need to collect personal data from you from time to time which is
considered to be sensitive. Such data can only be processed by us when you
give us your explicit consent to do so, or if it is necessary to do so to
protect your vital interests (e.g. in a medical emergency), or if you make
such data public yourself:
</p>
<ul>
<li>
<strong><strong>information about your physical or mental health.</strong></strong>
</li>
</ul>
<p>
You agree and acknowledge that any individual’s Protected Health
Information that comes within our custody, exposure, possession or knowledge
or is created, maintained, retained, transmitted, derived, developed,
compiled, prepared or used by us in the course of or in connection with the
performance of services under this Agreement, is confidential and shall remain
the exclusive property of our Company and shall be used, disclosed,
transmitted and/or maintained solely in accordance with our Terms and
Condition and as Required By Law. We agree to comply with our obligations as a
Business Associate and acknowledge that we are subject to and agree to comply
with HIPAA and all applicable guidance and regulations issued by the
government to implement HIPAA and all other applicable law.
</p>
<p><strong>THE USE OF THIRD-PARTY CONNECTIONS</strong></p>
<p>
In some circumstances, we may also receive personal information about you via
third parties.&nbsp;
</p>
<p>
If you access our App through a third-party connection or log-in, such as
Google or Facebook, that third party may pass on certain information about
your use of its service to us. This information could include the user ID
associated with your account (for example, your Facebook UID), an access token
necessary to access that service, any information that you have permitted the
third party to share with us, and any information you have made public in
connection with that service. We do not control how such third parties process
and share your personal information with us. You should therefore always
review, and if necessary, adjust your privacy settings on such third-party
sites and services before linking or connecting them to us. You may also
unlink your third-party account from our App by adjusting your settings on the
relevant third-party service.
</p>
<p>
Our App may also include social media features, such as the Facebook Like
button. Our integration of these features may allow third-party social media
to collect certain information about you, such as your IP address, and to set
a cookie to enable the feature to function properly. Social media features are
either hosted by a third party or hosted directly through the use of our App.
Your interactions with these features are governed by the privacy policy of
the company providing the feature.
</p>
<p>
<strong>PURPOSES AND LAWFUL BASES FOR PROCESSING YOUR PERSONAL DATA</strong>
</p>
<p>
Most of your personal data is collected and processed so that we can offer you
the full range of benefits and functionalities of our App. This includes to
offer you our products and services and to let you participate in our
community. This processing is necessary to perform our contract with you and
to provide you with the products and services we offer.&nbsp;
</p>
<p>
We will sometimes process personal data to comply with legal obligations
imposed on us, for example in relation to the prevention of fraud and money
laundering or in relation to tax regulations.&nbsp;
</p>
<p>
We may also use your personal data if it is in our legitimate interests to do
so. Such interests include our ongoing effort to provide, improve and
safeguard the different functionalities we offer.
</p>
<p>
If none of the above lawful bases apply to the processing of your data, we
rely on your consent to process such data. For example, if you enable your
location on your device, this is your consent for us to process your location.
If you do not want us to process your location, you can turn off this feature
on your device. However, if you choose to turn off the location on your device
you won’t be able to enjoy all of the functionalities of our App,
because some of those will be based on your location. Besides personal data
that we need to fulfill our obligations to you, you will also have the option
to submit more information about yourself. This is done on a voluntary basis.
By submitting such additional information, you express your consent to process
such data. You also have to give us your consent to enable us to use your
personal data for marketing purposes, such as sending you our newsletter by
e-mail. We will gather your consent when you sign up for an account. Whenever
we rely on your consent to process your personal data, you can withdraw such
consent at any time.&nbsp;
</p>
<h5><strong>ACCESSING AND DELETING YOUR PERSONAL INFORMATION</strong></h5>
<p>
We commit to maintaining the confidentiality of Your Personal Data and
ensuring that You can exercise Your rights.
</p>
<p>
You have the following rights under this Privacy Policy and by law, if you
live in the EU:
</p>
<ul>
<li>
Make a request for access to Your Personal Data. You have the right to
access, correct, or remove the information We have on file for You. You may
personally Contact Us so that We may assist You with your request. This also
allows You to obtain a copy of the Personal Data We have on file for You.
</li>
<li>
Request that We correct the Personal Data that We have on file for You. You
have the right to have any missing or incorrect information We have on file
for You rectified.
</li>
<li>
You may object to the processing of Your Personal Data. This right arises
when We rely on legitimate interest as the legal basis for Us processing and
something about Your particular circumstances causes You to object to our
processing of Your Personal Data on this basis. You also have the right to
object if We use Your Personal Data for direct marketing purposes.
</li>
<li>
Request that Your Personal Data be erased. You have the right to request
that We delete or remove Personal Data when there is no compelling reason
for Us to keep it.
</li>
<li>
Make a request for the transfer of Your Personal Data. We shall provide Your
Personal Data in a structured, commonly used, machine-readable manner to You
or a third party You have designated. Please keep in mind that this
privilege only applies to automated information that You gave Us permission
to use or where We utilized the information to perform a contract with You.
</li>
<li>
Withdraw Your permission. You have the right to revoke your consent to the
use of your personal data. We may be unable to provide You with access to
some specific functionality of the Service if You withdraw Your consent.
</li>
</ul>
<p>
You may exercise Your rights to access, rectify, cancel, and oppose by writing
Us at team@vibemedia.app, or by visiting our website.
</p>
<p>Vibe Media Limited</p>
<p>Partners Life</p>
<p>Level 1 33 - 45 Hurstmere Road,</p>
<p>Takapuna, Auckland, 0622</p>
<p>New Zealand</p>
<p>
Please keep in mind that before replying to such inquiries, we may ask You to
verify Your identity. We will do our best to answer You as quickly as possible
if You make a request.
</p>
<p>
You have the right to lodge a complaint with a Data Protection Authority over
the collection and processing of Your Personal Data by Us. If You are in the
European Economic Area (EEA), please contact Your local data protection
authority in the EEA for more information.
</p>
<p><strong>AGE REQUIREMENT</strong></p>
<p>
Our App is not intended for children under the age of 16 or the applicable
legal age for data subjects to be able to express their consent in your
country of residence, if this is a different age in your country of residence.
We do not knowingly collect or distribute personal information from or about
children under the age of 16 or such other applicable legal age. If a parent
or guardian becomes aware that his or her child has provided us with personal
information without their consent, he or she should contact us so that we can
remove such data immediately from our servers.
</p>
<p><strong>COOKIES</strong></p>
<p>
Whenever you visit our website via a link in our App, a cookie (a small text
file) may be placed onto the electronic device you use to visit our website.
Cookies can be used to help you navigate websites more efficiently and to
perform certain functions, as well as to provide information to us. There are
several types of cookies and other online tracking devices such as web
beacons, action tags, local shared objects or flash cookies, and single-pixel
gifs. Cookies can perform different functions and serve different purposes:
</p>
<ul>
<li>
Session cookies: these allow our website to link your actions during a
particular browser session. These expire each time you close your browser
and do not remain on your device afterwards.
</li>
<li>
Persistent cookies: these are stored on your device in between browser
sessions. These allow your preferences or actions across our website to be
remembered. These will remain on your device until they expire, or you
delete them from your cache.
</li>
<li>
Strictly necessary cookies: these cookies are essential for you to be able
to navigate our website and use its features. Without these cookies, the
services you have asked for could not be provided.
</li>
<li>
Performance cookies: these cookies collect information about how you use our
website, e.g. which pages you go to most often. These cookies do not collect
personally identifiable information about you. All information collected by
these cookies is aggregated and anonymous, and is only used to improve how
our website works.
</li>
<li>
Functionality cookies: these cookies allow our website to remember the
choices you make (such as your user name, language, last action and search
preferences) and provide enhanced, more personal features. The information
collected by these cookies is anonymous and cannot track your browsing
activity on other websites.&nbsp;
</li>
</ul>
<p>
We will ask for your permission (consent) to place cookies or other similar
technologies on your device, except where these are essential for us to
provide you with a service that you have requested (e.g. to enable you to put
items in your shopping basket and use our check-out process).
</p>
<p>
If you have accepted cookies, you can always withdraw your consent by
adjusting the privacy preferences of your browser. Please be aware that
restricting or deleting cookies may impact on the functionality of our
website.
</p>
<p>
The table below provides more information about the cookies we currently use
and why:
</p>
<p>
<em>(insert table with the cookies you currently use, including their name and
owner and a general description of what they do)</em>
</p>
<p><strong>SHARING YOUR PERSONAL DATA </strong></p>
<p>
We would like to stress that we do not sell your personal information to any
other party.&nbsp;
</p>
<p>
However, we do share your personal information with the following categories
of third parties.&nbsp;
</p>
<ul>
<li>
<strong><strong>Our business partners, suppliers and sub-contractors&nbsp;</strong></strong>
</li>
</ul>
<p>
We share your personal data with them for the performance of any contract we
enter into with them or you. This includes, for example, a distributor, a
customer service provider, a payment provider, a technical maintenance
provider and a marketing automation provider.
</p>
<ul>
<li>
<strong><strong>Our online store hosting provider&nbsp;</strong></strong>
</li>
</ul>
<p>
We use Shopify Inc. as a host of our online e-commerce store, allowing us to
sell our products and services to you. If you use our online store, you should
also refer to the privacy policy of Shopify (which can be found via
<em>this link</em>).&nbsp;
</p>
<ul>
<li>
<strong><strong>Credit and debit card companies&nbsp;</strong></strong>
</li>
</ul>
<p>
Credit and debit card companies are used to facilitate your payments to us,
and for anti-fraud screening. They need information about your method of
payment to process payment or ensure the security of your payment transaction.
These may include third party payment gateway providers, including at
www.stripe.com or www.paypal.com or www.apple.com. Before entering your
personal details we suggest that you read and become familiar with the privacy
policy for any such third-party provider.
</p>
<ul>
<li>
<strong><strong>HealthKit Framework and Healthkit API.</strong></strong>
</li>
</ul>
<p>
Notwithstanding the foregoing, Madfit App will not use or disclose to third
parties user data gathered from Apple, Inc. Healthkit framework or Healthkit
API for advertising or other use-based data mining purposes other than
improving health, or for the purpose of health research. In no event will any
information be collected from Healthkit users for use by third parties in
compliance with this section without obtaining user consent.
</p>
<ul>
<li>
<strong><strong>Other circumstances in which we may share your personal data and with
whom</strong></strong>
</li>
</ul>
<p>We will also share your personal data in the following circumstances:</p>
<ul>
<li>
if we or substantially all of our assets are acquired by a third party, in
which case personal data held by us about our users will be one of the
transferred assets;
</li>
<li>
if we are under a duty to disclose or share your personal data in order to
comply with any laws, legal obligations or investigations;
</li>
<li>to enforce or apply our Terms of Use, policies or other agreements;</li>
<li>
to protect the rights, property, or safety of Vibe Media Limited, its
employees, our users or third parties. This includes exchanging information
with other companies and organisations for the purposes of fraud protection.
</li>
</ul>
<p>
In the event we receive a legal request to disclose your personal data, we may
notify you of such request before we do so, unless this is expressly
prohibited by the authority making the request or if notifying you would be
detrimental to us, the party requesting your personal information, or any
other person affected, or to the investigation itself. If we can’t
notify you of such requests beforehand for the reasons set out before, we will
try to notify you afterwards.
</p>
<p><strong>HOW AND FOR HOW LONG WE KEEP YOUR PERSONAL DATA </strong></p>
<p>
We put appropriate security measures in place to prevent personal data from
being accidentally lost, or used or otherwise accessed in an unauthorised way.
We limit access to your personal information to those who have a genuine
business need to know it. Those processing your information will do so only in
an authorised manner and are subject to a duty of confidentiality.
</p>
<p>
All information you provide to us is stored on secure servers. Any payment
transactions will be encrypted (using SSL technology). SSL is the industry
standard method of encrypting personal information and credit card details so
that they can be securely transferred over the internet.
</p>
<p>
You are responsible for keeping your password confidential. We ask you not to
share a password with anyone.
</p>
<p>
Unfortunately, the transmission of information via the internet is not
completely secure. Although we will do our best to protect your personal data,
we cannot guarantee the security of your data transmitted to our App; any
transmission is at your own risk. Once we have received your information, we
will use strict procedures and security features to try to prevent
unauthorised access.
</p>
<p>
We only retain your personal data for as long as your account is active or for
as long as we need it to fulfil the purposes for which we have initially
collected it, unless otherwise required by law. We may retain certain
information for longer to comply with applicable laws, to prevent fraud, or
for obligations we have in relation to tax regulations.&nbsp;
</p>
<p><strong>TRANSFER OF PERSONAL INFORMATION OUTSIDE THE EEA</strong></p>
<p>
Our App may be used by users located all over the world. If you are a user
located in the European Economic Area (EEA): the personal data that we receive
and collect from you may be transferred to, and stored at, a destination
outside the EEA. It may also be processed by staff operating outside the EEA
who work for us or for one of our suppliers.&nbsp;
</p>
<p>
Such countries may not have the same data protection laws as the EU. In the
event your personal information is transferred to such countries, we have
ensured that appropriate safeguards are put in place to protect your privacy
rights and give you remedies in the unlikely event of a misuse of your
personal information.&nbsp;
</p>
<p>We will not otherwise transfer your personal data outside of the EEA.</p>
<p><strong>YOUR RIGHTS </strong></p>
<p>
Under data protection laws, you have the following rights, which you can enjoy
free of charge:&nbsp;
</p>
<ol>
<li>
Right of transparency and information about how we use your personal
information.
</li>
<li>
Right to ask us to confirm whether or not we hold any personal information
from you and if we do, the right to ask us what information we have stored
about you and for a copy of that information.
</li>
<li>
Right to rectify any inaccurate personal data we hold about you or to
complete any incomplete information.&nbsp;
</li>
<li>
Right to have all personal data we keep from you erased in the following
situations:
<ul>
<li>
the personal data are no longer necessary in relation to the purpose for
which it was collected;
</li>
<li>
you have withdrawn your consent to process your data and we do not have
any other legal ground to keep processing your data;
</li>
<li>
you object to the processing of your data while we do not have any
overriding legitimate grounds to do so;
</li>
<li>your data have been unlawfully processed.&nbsp;</li>
</ul>
</li>
<li>Right of restriction of processing</li>
</ol>
<p>
You have the right to ask us to restrict the processing of your personal data
if either one of the following applies:
</p>
<ul>
<li>
if you contest the accuracy of the personal data, for a period so long that
we can verify the accuracy of your personal data;
</li>
<li>
if the processing is unlawful and you oppose the erasure of your data and
ask for a restriction instead;
</li>
<li>
we do no longer need your personal data for the purpose for which it was
collected, but you require your data for the purpose of establishment,
exercise or defence of legal claims;
</li>
<li>
you have objected to the processing pending verification of the legitimate
grounds to override such objection.
</li>
<li>
Right to object to:
<ul>
<li>
processing of personal data concerning you based on legitimate interests
or the performance of a task in the public interest. Such objection must
be based on grounds relating to your particular situation. We are
obliged to stop processing your data, unless either we can demonstrate
that we have compelling legitimate grounds to override your interests,
rights and freedoms or the processing is for the establishment, exercise
or defence of legal claims;
</li>
<li>direct marketing.</li>
</ul>
</li>
<li>
Right to withdraw consent you have given us to process your personal data,
where consent is being relied on as the lawful basis of such
processing.&nbsp;
</li>
<li>
Right to receive the personal data concerning you in a structured, commonly
used, machine-readable format and, if technically possible, have this
transferred to another service provider.&nbsp;
</li>
</ul>
<p>
You can exercise your rights by checking certain boxes on the forms we use to
collect your data, by adjusting your settings in your profile and by
contacting us. We may request you for proof of identity and address when you
exercise your rights.&nbsp;
</p>
<p><strong>CHANGES TO THIS POLICY </strong></p>
<p>
We may make changes to this Policy from time to time. We will notify you via
our App if any material changes have been made to this Policy and/or we may
e-mail you our revised Policy. If we need your renewed consent for anything,
we will ask you for it.&nbsp;
</p>
<p><strong>MAKE A COMPLAINT</strong></p>
<p>
If you encounter any problems in relation to the use of your personal
information, please contact us so we can try to resolve your issue or concern.
If you are a user based in an EU member state you also have the right to lodge
a complaint with the supervisory authority in the EU member state where you
work, normally live or where any alleged data infringement of data protection
has occurred.&nbsp;
</p>
<p><strong>HOW TO CONTACT US </strong></p>
<p>
Questions, comments, and requests regarding this Policy are welcomed and
should be addressed to the data controller:
</p>
<p>Vibe Media Limited</p>
<p>Partners Life,</p>
<p>Level 1 33 - 45 Hurstmere Road,</p>
<p>Takapuna, Auckland, 0622</p>
<p>New Zealand</p>
<p>e-mail: team@vibemedia.app</p>
<p>© {new Date().getFullYear()} Vibe Media Limited</p>
<div id="loom-companion-mv3" ext-id="liecbddmkiiihnedobmlmillhodjkdmb"><section id="shadow-host-companion"></section></div>

    </>
  )
}

export default CustomModalPopup
