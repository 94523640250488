import React from 'react'
import Madfit_logo from '../assets/images/images/madfit-logo.svg'
import {
 
	Link 
  } from "react-router-dom";

const MainLanding = () => {
  return (
    <>
      <section class="survey-sec">

<div class="top-l-circle">
  <img src={require("../assets/images/images/full-circle2.png")} alt="full-circle"/>
</div>

<div class="bottom-r-circle">
  <img src={require("../assets/images/images/full-circle2.png")} alt="full-circle"/>
</div>

 <div class="survey-area">
     <div class="survey-img">
         <img src={require("../assets/images/images/Survey-landing.png")} alt="Survey-landing"/>
     </div>

     <div class="survey-content">
        <a href="#" class="servey-logo"><img src={Madfit_logo} alt="MadFit-logo"/></a>
        <h1>Let’s get to know you</h1>
        <p> Answer a few questions to help us understand you a little better and recommend where to start on your fitness journey. </p>
        <Link to="/" class="site-btn take-servey">Take Survey</Link>
        <Link to="/home" class="site-btn skip-servey">Skip Survey</Link>
     </div>
     

 </div>

</section>
    </>
  )
}

export default MainLanding
