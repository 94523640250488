import React from 'react'
import Madfit_logo from '../assets/images/images/madfit-logo.svg'
import Header from '../components/Header'
import Footer from '../components/Footer'

import {
 
	Link 
  } from "react-router-dom";

const Trainer = () => {
  return (
    <>
<Header/>
<section class="trainer-box">
  <div class="container">
    <div class="container-inner">
    <img class="circle-img-expert" src={require("../assets/images/images/full-circle2.png")} alt="full-circle"/>
     <div class="row">
        <div class="col-lg-8">
           <div class="trainer-content">
              <div class="trainer-heading">
                 <h2>Maddie lymburner</h2>
                 <span class="trainer-icons">
                    <a target="_blank" href=' https://www.tiktok.com/@mad_fit'> <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.3666 6.79C18.5691 5.87956 18.1296 4.71034 18.1299 3.5H14.5249V17.9667C14.4971 18.7495 14.1666 19.4911 13.603 20.0351C13.0394 20.5791 12.2866 20.8832 11.5033 20.8833C9.8466 20.8833 8.46994 19.53 8.46994 17.85C8.46994 15.8433 10.4066 14.3383 12.4016 14.9567V11.27C8.37661 10.7333 4.85327 13.86 4.85327 17.85C4.85327 21.735 8.07327 24.5 11.4916 24.5C15.1549 24.5 18.1299 21.525 18.1299 17.85V10.5117C19.5918 11.5615 21.3469 12.1248 23.1466 12.1217V8.51667C23.1466 8.51667 20.9533 8.62167 19.3666 6.79Z" fill="#32BAB0"/>
</svg> </a>
                     <a target="_blank" href='https://www.instagram.com/madfit.ig'> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z" fill="#32BAB0"/>
</svg> </a>
                 </span>
              </div>
              <p>Helping people feel empowered and making fitness accessible to everyone is what Maddie Lymburner is most passionate about. Starting her Youtube channel in 2018, Maddie’s approach to a welcoming and positive fitness routine gained more than a million followers in only a year.<br/><br/>

              During the Covid-19 Pandemic when so many people were stuck indoors, millions more turned to Maddie to help stay fit, active and encouraged with all the varied exercises and feel-good dance party workouts the MadFit channel offered. The community that Maddie helped grow through these difficult times earned her an award from Google as one of the top Canadian Youtubers of 2021. <br /> <br />

              The MadFit story didn’t stop there, as Maddie created the MadFit App with a huge selection of new workouts and programs to help everyone achieve their goals. An instant success, MadFit earned the App Of The Day award on the Apple App Store. Since then, the MadFit App has kept growing and branching out into new ways to stay happy and healthy.

               </p>

            <Link class="site-btn take-servey" to="/sign-up">Join Maddie in the Madfit App</Link>
           </div>
        </div>
        <div class="col-lg-4">
          <div class="trainerimg">
             <img src={require("../assets/images/images/Rectangle-3.png")} alt="Trainer Biography"/>
          </div>
        </div>
     </div>
    </div>
  </div>
</section>

<section class="trainer-box ariana-box">
  <div class="container">
    <div class="container-inner">
    <img class="circle-img-expert" src={require("../assets/images/images/full-circle2.png")} alt="full-circle"/>
     <div class="row">
        <div class="col-lg-8">
           <div class="trainer-content">
              <div class="trainer-heading">
                 <h2>Arianna Elizabeth</h2>
                 <span class="trainer-icons">
                    {/* <a href='#'> <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.3666 6.79C18.5691 5.87956 18.1296 4.71034 18.1299 3.5H14.5249V17.9667C14.4971 18.7495 14.1666 19.4911 13.603 20.0351C13.0394 20.5791 12.2866 20.8832 11.5033 20.8833C9.8466 20.8833 8.46994 19.53 8.46994 17.85C8.46994 15.8433 10.4066 14.3383 12.4016 14.9567V11.27C8.37661 10.7333 4.85327 13.86 4.85327 17.85C4.85327 21.735 8.07327 24.5 11.4916 24.5C15.1549 24.5 18.1299 21.525 18.1299 17.85V10.5117C19.5918 11.5615 21.3469 12.1248 23.1466 12.1217V8.51667C23.1466 8.51667 20.9533 8.62167 19.3666 6.79Z" fill="#32BAB0"/>
</svg> </a> */}
                     <a target='_blank' href='https://www.instagram.com/brightxsaltedyoga/'> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z" fill="#32BAB0"/>
</svg> </a>
                 </span>
              </div>
              <p>Arianna is a certified and experienced yoga instructor who has been helping bring the joys and appreciation of yoga to people’s lives for more than 8 years. Teaching at her own Yoga Studio or making her classes accessible to everyone on her YouTube channel, hundreds of thousands of people join Arianna to help find awareness and intention in their movements with yoga.<br/><br/>

              A safe and welcoming space for all is what Arianna strives for - giving everyone a chance to respect the vast benefits of yoga whilst reflecting on their own personal beliefs. Ariana crafts her classes to offer asanas that build functional, intrinsic strength that can be used in people’s daily lives.   <br /> <br />
              Arianna joined Maddie in the MadFit family to help bring all the benefits yoga can offer to the thousands of subscribers that already enjoy Maddie’s classes, adding a new reflective and spiritual direction to the community.
               </p>
            <Link class="site-btn take-servey" to="/sign-up">See Arianna’s Classes in the App</Link>
           </div>
        </div>
        <div class="col-lg-4">
          <div class="trainerimg">
             <img src={require("../assets/images/images/Rectangle-2.png")} alt="Survey-landing"/>
          </div>
        </div>
     </div>
    </div>
  </div>
</section>
<Footer/>

    </>
  )
}

export default Trainer
