import React from "react";
import { useEffect, useState,useRef } from "react";
// import Header_Logo from "../assets/images/images/Header_Logo.svg";
import Header_Logo from "../assets/images/images/Horizontal-Logo-Dark.svg";

import mf_logo_solo from "../assets/images/images/mf_logo_solo.svg";
import PaymentGateway from "../components/PaymentGteway";
import dropin from "braintree-web-drop-in";
import { Navigate, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import $ from "jquery";

import {
  ERROR_MESSAGE,
  MONTHLY_PLAN_ID,
  MONTHLY_PLAN_DESC,
allowableServerErrors 
} from "../components/planDetails";
import back_arrow from '../assets/images/images/arrow-back_1.svg'
import CustomModalPopup from "../components/CustomModalPopup";

const Payment = () => {
  let navigate = useNavigate()
  let location = useLocation();


  const [isSignUp, setIsSignUp] = useState(false);
  const [isSignin, setIsSignIn] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [checker, setChecker] = useState(false);
  const [clientToken, setClientToken] = useState();
  const [show, setShow] = useState(false);
  const braintreeContainerRef = useRef(null)

  const [inputValue, setInputValue] = useState({
    email: "",

    // 'multiple_query':''
    // 'url':''
  });
  

  const [braintreeInstance, setBraintreeInstance] = useState(undefined);
  
  const [braintreeLoading, setIsBraintreeLoading] = useState(true);
  const initializeBraintree = () => setIsBraintreeLoading(true);
  let base_url=process.env.REACT_APP_prod_base_url
  // let useEffectCount=0
  
  dropin.create(
    {
    
      authorization: clientToken,
     
      container:braintreeContainerRef.current
    },
    function (error, instance) {

      if (error) {
        console.log(error,"i am error of braintree instance")
        // toast.error(error?.message || ERROR_MESSAGE)
        // toast.error(error?.message || ERROR_MESSAGE, {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        //   });
        // toast.error(error.message);
      } else {
        // useEffectCount=1
        console.log("innn-ppp")
        setIsBraintreeLoading(false);
        setBraintreeInstance(instance);
      }
    }
  );





  const resetBraintreeInstance = () => {
    braintreeInstance.teardown().then(() => {
      setBraintreeInstance(null);
      initializeBraintree();
    });
  };
  useEffect(() => {
    setInputValue({ ...inputValue, email: location.state.email });
//     if(useEffectCount==0)
// {
    handleBraintreeInit();
    return () => {
      if (braintreeInstance) {
        braintreeInstance.teardown();
      }
    };
  }, []);
  const changeInput = (e) => {
    const { name, value } = e.target;

    setInputValue({
      ...inputValue,

      [name]: value,
    });
  };
  const handleBraintreeInit = async () => {
    let session_item = JSON.parse(window.localStorage.getItem("session"));
    // console.log(JSON.parse(session_item).token,session_item,"session_itemm")
    let token = `Bearer ${location.state.app_access_token}`;
    console.log(token, "tokennn");
    setIsBraintreeLoading(true);
    try {
      
      const { data } = await axios.get(
        base_url+"api/subscription/token",
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(data, "dattaaaa");
      setClientToken(data.token);
    
      if (braintreeInstance) {
        resetBraintreeInstance();
      } else {
        initializeBraintree();
      }
    } catch (ERROR) {
      console.log(ERROR, "ERRRORRRR");
      // toast.error(ERROR_MESSAGE)
      toast.error(ERROR_MESSAGE, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      
    
    }
  };

  const handleBuy = () => {
    let token = `Bearer ${location.state.app_access_token}`
    console.log("innn");
  
    braintreeInstance.requestPaymentMethod((error, payload) => {
      if (error) {
       
        // toast.error(error?.message || ERROR_MESSAGE)
        toast.error(error?.message || ERROR_MESSAGE, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
        
      } else {
        
        const payment = {
          pm_nonce: payload.nonce,
          
          plan_id: MONTHLY_PLAN_ID,
         
          descriptor:MONTHLY_PLAN_DESC
        }

        axios
          .post(base_url+'api/subscription/create', payment, {
            headers: {
              Authorization:token ,
            },
          })
          .then((res) => {
            console.log(res,"resp")
            window.localStorage.setItem('purchased', JSON.stringify(true))
            navigate('/order-complete')
            
        
          })
          .catch((error) => {
            resetBraintreeInstance()
        
            const errorMessage =
              error?.response?.data?.message || error?.message
            // toast.error(
            //   allowableServerErrors.includes(errorMessage)
            //     ? errorMessage
            //     : 'We apologize, but we were unable to process the payment using your card. Kindly reach out to your card issuer for assistance. Please take note that Amex and Discover cards are not yet supported at this time.'
            // )
            toast.error( allowableServerErrors.includes(errorMessage)
            ? errorMessage
            : 'We apologize, but we were unable to process the payment using your card. Kindly reach out to your card issuer for assistance. Please take note that Amex and Discover cards are not yet supported at this time.', {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              });
            
          })
      }
    })
  };
  
  const popupFunction=()=>{
    $(".custom-model-main").addClass('model-open');
    // $(".Click-here").on('click', function() {
    // }); 
  }
  const removePopupFunction=()=>{
    console.log('abc')
    $(".custom-model-main").removeClass('model-open');
    // $(".Click-here").on('click', function() {
    // }); 
  }
  const backMenu=()=>{

    navigate(-1);
  
  }
  return (
    <>
      {
//     (braintreeLoading) ?
//         <div id="payment_loader" className="loadingParent">
// {console.log(braintreeLoading)}
//         <div class="lds-ripple">
//           <div></div>
//           <div></div>
//         </div>
//       </div>
// :

      <section
        className="sign_in position_relative payment-info"
      
        id="container_main_id"
        // ref={braintreeContainerRef}
      >
            <ToastContainer

/>


<div className="back_btn_info">

<button class="" onClick={() => backMenu()} >
      <img src={back_arrow} alt="" />
      {/* <span></span> */}
    </button>
</div>
        <div className="sign_in_bg_left">
        {console.log(braintreeLoading)}
          <img
            src={require("../assets/images/images/full-circle2.png")}
            alt=""
          />
        </div>
        <div className="sign_in_bg_right">
          <img
            src={require("../assets/images/images/full-circle2.png")}
            alt=""
          />
        </div>
        <div className="container">
          {/* <div className="sign_in_bg_right" >
  <img src={require("../assets/images/images/full-circle2.png")} alt="" />
  </div> */}
          <div className="sign_in_inner">
            <div className="sign_in_inner_content signin_payment">
              {/* <div id='imageParent' className="loadingParent">
         <div class="lds-ripple"><div></div><div></div></div>
         </div> */}
              <div className="logo_div">
                <a href="#">
                  <img
                    src={Header_Logo}
                    alt="Madfit logo"
                    className="logo_class"
                  />
                </a>
              </div>
              <div className="sign_in_c1">
              <h2 className="step_01">Step 1 - Sign in <span>. Step 2 - Confirm and pay </span></h2>
              </div>

              <div className="sign_in_c2 payment_c2">
                <h1 className="upcase"> Confirm and pay</h1>
              </div>

              <div className="payment_box">
                <div className="payment_box_logo">
                  <img src={Header_Logo} alt=" Header logo" />
                </div>
                <div className="payment_detail">
                  <p> Madfit 1 Month Membership </p>
                  <p className="strike_price">$19.99</p>
                </div>
                <div className="payment_detail">
                  <h2>
                    {" "}
                    Madfit Membership Special Offer{" "}
                    <div className="offer upcase">save 95%</div>{" "}
                  </h2>
                  <h2>$1.00</h2>
                </div>
                <div className="payment_detail payment_sum">
                  <h2>Total Due Today </h2>
                  <h2>$1.00</h2>
                </div>
              </div>
              {
                (location.state.email)&&
              <div className="form_section">
                <form className="sign_up_form">
                  <div className="position_relative form-group input_parent">
                    {/* <label htmlFor='email_address'>Email Address</label> */}
                    <input
                      type="text"
                      id="email_address"
                      className="fullInput"
                      name="email"
                      value={inputValue.email}
                      onChange={changeInput}
                      disabled
                      placeholder="Email"
                    />
                    {/* <div class="invalid-feedback">
    First name is required
          
           </div> */}
                  </div>
                  {/* <div class="sign_in_btn_pos"><button class="site-btn sign_in_btn payment-btn" id="" type="submit">
           <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.6433 3.53564H2.35756C1.48969 3.53564 0.786133 4.2392 0.786133 5.10707V16.8928C0.786133 17.7607 1.48969 18.4642 2.35756 18.4642H19.6433C20.5112 18.4642 21.2147 17.7607 21.2147 16.8928V5.10707C21.2147 4.2392 20.5112 3.53564 19.6433 3.53564Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M0.786133 9.03564H21.2147M14.929 14.5356H17.2861" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
            Debit or Credit Card</button></div> */}
                </form>
              </div>
}
              <div class="sign_in_btn_pos" id="payment_toggle_id">
                <button
                  class="site-btn sign_in_btn payment-btn"
                  onClick={() => {
                    setShow(true);
                    $("#payment_toggle_id").css("display", "none");
                    // window.dataLayer.push({
                    //   event: 'pageview'
                    // });
                    // window.dataLayer.push({event:'subscribe'})
                  }}
                  id=""
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.6433 3.53564H2.35756C1.48969 3.53564 0.786133 4.2392 0.786133 5.10707V16.8928C0.786133 17.7607 1.48969 18.4642 2.35756 18.4642H19.6433C20.5112 18.4642 21.2147 17.7607 21.2147 16.8928V5.10707C21.2147 4.2392 20.5112 3.53564 19.6433 3.53564Z"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M0.786133 9.03564H21.2147M14.929 14.5356H17.2861"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Debit or Credit Card
                </button>
              </div>
              <PaymentGateway
                show={show}
                braintreeInstance={braintreeInstance}
                setBraintreeInstance={setBraintreeInstance}
                handleBuy={handleBuy}
                braintreeContainerRef={braintreeContainerRef}
              />

            
              <p class="btpara">This offer is for new customers only. By signing up, you agree to our <a onClick={popupFunction}> Terms and conditions.</a> You will be billed $1.00 USD immediately and
                   will be charged $19.99 USD to the same payment details unless you unsubscribe. <strong> You may unsubscribe from membership at any time and will not be required to pay anything further.</strong> </p>
              
            </div>
          </div>
        </div>
      </section>
      
}
<div class="custom-model-main">
        <div class="custom-model-inner">        
        <div class="close-btn" onClick={removePopupFunction}></div>
            <div class="custom-model-wrap">
                <div class="pop-up-content-wrap">
                 <CustomModalPopup/>
             </div>    
            </div>  
        </div>  
        <div class="bg-overlay" onClick={removePopupFunction}></div>
      </div>
    </>
  );
};

export default Payment;
