import React,{useEffect,useState} from 'react'
import { GoogleOAuthProvider,GoogleLogin , useGoogleLogin,MyCustomButton} from '@react-oauth/google';
import Axios from 'axios'
import { FacebookProvider, useLogin,LoginButton,useProfile, status, isLoading, error,useFacebook } from 'react-facebook';
import "../assets/css/feature.css"
// import Header_Logo from '../assets/images/Header_Logo.png'
import Header_Logo from '../assets/images/images/madfit-logo.svg'
import mf_logo_solo from '../assets/images/images/mf_logo_solo.svg'
import apple_logo from '../assets/images/images/apple_logo.svg'
import google_logo from '../assets/images/images/google_logo.svg'
import facebook_logo from '../assets/images/images/facebook_logo.svg'
// import {  useProfile } from 'react-facebook';
import $ from 'jquery'


import {
  LoginSocialGoogle,
  LoginSocialAmazon,
  LoginSocialFacebook,
  LoginSocialGithub,
  LoginSocialInstagram,
  LoginSocialLinkedin,
  LoginSocialMicrosoft,
  LoginSocialPinterest,
  LoginSocialTwitter,
  LoginSocialApple,
  IResolveParams,
} from 'reactjs-social-login';
import AppleLogin from 'react-apple-login'




// facebook log in sso in react
// https://blog.logrocket.com/guide-adding-google-login-react-app/
// https://dev.to/quod_ai/how-to-integrate-facebook-login-api-into-your-react-app-33de

// https://blog.logrocket.com/guide-adding-google-login-react-app/
const SignIn = () => {
  // const { profile } = useProfile(['id', 'name']);
  const {  init ,getAppVersion} = useFacebook();

  const { login, status, isLoading, error} = useLogin();
  const [isSignUp,setIsSignUp]=useState(false)
  const [isSignin,setIsSignIn]=useState(false)

  const [inputValue, setInputValue] = useState({
    firstName:'',
    lastName: "",
 emailId:'',
 password:''

    // 'multiple_query':''
    // 'url':''
})
const [isSubmit,setIsSubmit]=useState(false)
const[formerror,setFormError]=useState({})
const [signupResponse,setSignupResponse]=useState({})
const [fbLoginInfo,setFbLoginInfo]=useState({})
const [googleResponse,setGoogleResponse]=useState({})



const changeInput = e => {
      
  const { name, value } = e.target



  setInputValue({
    ...inputValue,
    //for random id in local storage
    //  id:shuffle(numbers),
    //for id in local storage.....................
    // id:add1(),

    [name]: value
  })
}

// const submitAuth=(response)=>{



// }
// const facebookLogin=()=>{
//   console.log("innn")
// }
async function Fun(){

    const { profile } = useProfile(['id', 'name']);
    console.log(profile,"profile")



}


const getFbProfileApi=async(userId,accessToken)=>{
  const res = await Axios.get(`https://graph.facebook.com/${userId}?fields=name,email&access_token=${accessToken}`)
  const data = await res.data
  return data
}
  async function facebookLogin() {
    try {
      const response = await login({
        scope: 'email',
      });
      let res= response.authResponse
     let {name,email}= await getFbProfileApi(res.userID,res.accessToken)
     let fbObject={}
     Object.assign(fbObject,{email:email,socialProvider:'facebook'})
if(name){
  let name_array=name.split(" ")
  name_array.forEach((item,item_index)=>{
    if(item_index==0){
Object.assign(fbObject,{firstName:item})

    }
    else if(item_index==name_array.length-1){
      Object.assign(fbObject,{lastName:item})

    }

  })

}

console.log(fbObject,"fbObjecttt")

    //  let name_array=name.split(' ')
    //  (name_array.length>2)
// console.log(response.authResponse.accessToken);
// setFbLoginInfo(response)

// fetch('https://graph.facebook.com/v2.5/me?fields=email,first_name,last_name,friends&access_token=' + response.authResponse.accessToken)
// .then((response) => {
//     response.json().then((json) => {
//       console.log(json,"json")
//         const ID = json.id
//         console.log("ID " + ID);

//         const EM = json.email
//         console.log("Email " + EM);

//         const FN = json.first_name
//         console.log("First Name " + FN);
//     })
// })
// .catch(() => {
//     console.log('ERROR GETTING DATA FROM FACEBOOK')
// })


// console.log(profile,"profileee")

      // submitAuth(response.access_token)
    } catch (error) {
      console.log(error.message);
    }
  }
  // useEffect(()=>{
  //   // setFacebookLogin(response)
  //   // const { profile, isLoading, error } = useProfile(['id', 'name']);
  //   console.log(profile,"profile")


  // },[profile])
  const googleLogin = useGoogleLogin({
    onSuccess: codeResponse => {console.log(codeResponse)}
    
    // flow: 'auth-code',
  });


  
  // const access_token="ya29.a0AWY7CkmkM4N4uV9XBSxxTfsC5p5sQtDyx8p-DvrLlBhaLhiVENNNiSop6USC_REc14kUeIEX_J7Ak32fyG3jIyzaGOkqbmbDT7hJyeY1Yc-F5NDkY4-ZSuqV-nP5COn2SW6mrKgegohD24s1Ik9fkbFC0fhkBwaCgYKAbcSARESFQG1tDrpuAmufYLjSxKL-1ir92PVbw0165"
  // useEffect(()=>{
  //   (Object.keys(googleResponse).length>0) &&
    
  //   Axios
  //   .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`, {
  //       headers: {
  //           Authorization: `Bearer ${access_token}`,
  //           Accept: 'application/json'
  //       }
  //   })
    
  //   .then(async(res) => {
  //       console.log(res,"resss")

  //   })
  //   .catch((err) => console.log(err));


  // },[googleResponse])


  async function handleClick() {
    const api = await init();

    const response = await api.login({
      scope: 'email'
    }

    );
    console.log(response,"response")
    const FB = await api.getFB(); // Get original FB object
    
    // let p=await api.getTokenDetailWithProfile();
    let p=await getAppVersion
    // const fb_version=await api.getAppVersion()
    console.log(FB,p,"fb object")
  }

  useEffect(()=>{
    console.log(formerror,"errorllll")
    // console.log(Object,"object")
    if(Object.keys(formerror).length===0 && isSubmit)
{
console.log(inputValue,"inputValue")
// const formData = new FormData()
let formData={}
let extractedData={}
Object.keys(inputValue).forEach(key => {
if(inputValue[key])
{
extractedData[key]=inputValue[key]
}

})
console.log(extractedData,"extracteddata")

Object.keys(extractedData).forEach(key => {
  // if(key=="firstName"){

  //   formData.append(key, inputValue[key])
  // }

   if(key=="emailId"){


    // formData.append('email', inputValue[key])
    // Object.assign('email,inputValue[key])
    formData['email']=inputValue[key]
  }
  else{
    // Object.assign('pa',inputValue[key])
    formData[key]=inputValue[key]

    // formData.append(key, inputValue[key])
  }

  // else if(key=="emailId"){
  //   formData.append('email', inputValue[key])
  // }
})

// console.log(JSON.stringify(formData),"FORMdATAA")
// for (var pair of formData.entries()) {
//   console.log(pair[0]+ ', ' + pair[1]); 
// }


let customConfig = {
  headers: {
  'Content-Type': 'application/json; charset=utf-8'
  }
};

if(isSignUp){
Axios.post(
  'https://staging-api.maddielymburner.co/api/onboard',
 JSON.stringify(formData)
// formData
,  customConfig
)
  .then(response => {
    setInputValue({
      ...inputValue,
      firstName:"",
      lastName:"",
      email:"",
      password:""

    })
    setIsSignUp(false)
    if (response) {
      $('#reg_alert').css("display","block")
      setTimeout(()=>{

        $('#reg_alert').css("display","none")

      },[1000])
       
    }
  })
  .catch(function (error) {
    let errorObj={}
   let errorData= error.response.data.errors;
   Object.keys(errorData).forEach((key)=>{
   errorObj[key] =errorData[key][0]
   })
   setFormError(errorObj)
   console.log(errorData,errorObj)


 
    console.log(error, 'errrrrrrrrrrrrrrrrr')
  })
}
else{
  setIsSignIn(false)
 
    $('#sign_alert').css("display","block")
    setTimeout(()=>{

      $('#sign_alert').css("display","none")

    },[1000])
     
  
}
// https://www.w3schools.com/howto/howto_js_snackbar.asp



// fetch(
//   "https://staging-api.maddielymburner.co/api/onboard",
//   {
//     method: "POST",
//     mode: "no-cors",
//     headers: {
//       "Content-Type": "application/json; charset=utf-8",
//       // Authorization: "Basic " + btoa("111fasvnhfvs:@123456789qwert"),
//     },

//     body: JSON.stringify({ firstName: "Test12",
//     lastName: "User12",
//     email: "test12333@gmail.com",
//     password: "Test1234" }),
//   }
// )
//   .then((response) => {
//     console.log(response);
//     response.json();
//   })
//   .then((data) => {
//     console.log("Success:", data);
//   })
//   .catch((error) => {
//     console.error("Error:", error);
//   });

    }
  

},[formerror]

)


const submitThis=(e)=>
{

    e.preventDefault();

    
    setFormError(validate(inputValue))

    setIsSubmit(true)


}
const validate=(values)=>{

  const error={};
  const email_regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  
 if(!inputValue.emailId){
  error.email="Please provide an email"

 }
 else{
  //    const regxPhone = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/



    if(!email_regx.test(values.emailId)){
    error.email="Please provide a valid email"
    
  }
 }
 if(!inputValue.password){
  error.password="Please provide an password"
 }
 if(!inputValue.firstName){
  error.firstName="Please provide a first name"
 }




   return error;

}
const appleLoginPopup=()=>{

}
const facebookLoginPopup=()=>{
  
}
const googleLoginPopup=()=>{

}
    // https://blog.logrocket.com/guide-adding-google-login-react-app/
    // https://stackoverflow.com/questions/75692128/react-google-login-package-is-deprecated-what-is-to-be-the-replacement-for-the
    // https://stackoverflow.com/questions/68438293/the-given-origin-is-not-allowed-for-the-given-client-id-gsi
  return (

    <>
    {/* {
   (profile) && (console.log(profile.name,"profile"))
  } */}
    {console.log("profileee")}
    {/* {console.log("immmmm")}
    <h1 className="new">
      meeeeeee
    </h1> */}
    {/* {process.env.google_client_id} */}
    {/* <GoogleOAuthProvider clientId="134350539673-2vd8sfigqeeuj8qmvo10euj1pfip0gle.apps.googleusercontent.com"> */}
      {/* <GoogleLogin
  onSuccess={credentialResponse => {
    console.log(credentialResponse);
  }}
  onError={() => {
    console.log('Login Failed');
  }}
/>; */}
{/* <div style={{width:'50px',color:'red'}}>   
<button onClick={() => login()}>
  Sign in with Google 🚀{' '}
</button>;
</div> */}

{/* <FacebookProvider appId="1410886456425574"> */}


{/* <LoginButton
        scope="email"
        onError={(err)=>console.log(err,"error")}
        onSuccess={(obj)=>console.log(obj,"object")}
      >
        Login via Facebook
      </LoginButton> */}



    {/* <LoginSocialFacebook
          appId="1410886456425574"
          fieldsProfile={
            'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
          }
    
          onResolve={( provider, data ) => {
            console.log(data)
           
          }}
          onReject={err => {
            console.log(err,"errr ");
          }}
        >
        
          <button> click me</button>
        </LoginSocialFacebook> */}
{/* </FacebookProvider> */}

{/* </GoogleOAuthProvider> */}

<section className='sign_in position_relative'  >
  <div className="sign_in_bg_left" >
<img src={require("../assets/images/images/full-circle2.png")} alt="" />
  </div>
  <div className="sign_in_bg_right" >
<img src={require("../assets/images/images/full-circle2.png")} alt="" />
  </div>
  <div className="container">

  

  
  {/* <div className="sign_in_bg_right" >
  <img src={require("../assets/images/images/full-circle2.png")} alt="" />
  </div> */}
  <div className="sign_in_inner" >
    <div className="sign_in_inner_content">
<div className="logo_div">
  <img src={Header_Logo} alt="Madfit logo" className='logo_class' />
</div>
<div className="sign_in_c1">
<h2 className='step_01'>
Step 01 - Sign In
</h2>
<h2 className='step_02'>
Step 02 - Confirm and pay
</h2>


</div>
<div className="sign_in_c2">
  <h1 className='upcase'> Sign in</h1>
  
  <p>Orem ipsum dolor sit amet, consectetur adipiscing elit.
Excepteur sint occaecat cupidatat non proident.</p>

</div>
<div className="sign_in_options">
  <p>Login with social account</p>
  {/* <div className="sign_in_options_btn"> */}

<button className='site-btn sso_btn apple_sso' onClick={() => appleLoginPopup()} > <img src={apple_logo} alt="Apple_logo" /> Sign in with Apple </button>
<button className='site-btn sso_btn google_sso' onClick={() => googleLogin()}> <img src={google_logo} alt="Google_logo" /> Sign in with Google </button>

{/* 
    <LoginSocialFacebook
          appId="1014546396203247"
          fieldsProfile={
            'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
          }
    
          onResolve={( provider, data ) => {
            console.log(data)
           
          }}
          onReject={err => {
            console.log(err,"errr ");
          }}
        > */}
        
          {/* <button> click me</button> */}
          {/* <button className='site-btn sso_btn facebook_sso'> <img src={facebook_logo} alt="Facebook_logo" /> Sign in with Facebook </button>
        </LoginSocialFacebook> */}
<button className='site-btn sso_btn facebook_sso' onClick={()=>handleClick()}> <img src={facebook_logo} alt="Facebook_logo" /> Sign in with Facebook </button>
<a className="site-btn sign_in_btn" onClick={()=>{setFormError({});setIsSignUp(false);setIsSignIn(true)}}> 
<img src={mf_logo_solo} alt="Medfit solo lofo" />
Sign in
</a>
<p className='alert_pop' id='sign_alert' >Sign in successful ! </p>
{/* <div className="sign_in_ac_btn">
  
</div>
<div className="sign_in_ac_btn">
  
</div> */}
  <p> or create a new account</p>

<a className="site-btn sign_in_btn" onClick={()=>{setFormError({});setIsSignUp(false);setIsSignIn(false);setIsSignUp(true)}}> 
<img src={mf_logo_solo} alt="Medfit solo lofo" />
Create a Madfit account
</a>
<p className='alert_pop' id='reg_alert'>Registration successful ! </p>



</div>
{
  (isSignUp || isSignin)&&
<div className="form_section">
  <form className='sign_up_form' onSubmit={submitThis}> 

  <div  className="position_relative form-group input_parent" >
           <label htmlFor='first_name'> First Name</label>
            <input type="text" id="first_name" className="fullInput" name="firstName" value={inputValue.firstName} onChange={changeInput}  />
            {/* <div class="invalid-feedback">
    First name is required
          
           </div> */}
                {
           (Object.keys(formerror).length>0)&&
            <div className="formerror">
            
       { <p >{formerror.firstName } </p>}
        </div>
}
        
           </div>
           <div  className="position_relative form-group input_parent" >
           <label htmlFor='last_name'>Last Name</label>
            <input type="text" id="last_name" className="fullInput" name="lastName" value={inputValue.lastName} onChange={changeInput}  />
            {/* <div class="invalid-feedback">
    First name is required
          
           </div> */}
           {
           (Object.keys(formerror).length>0)&&
            <div className="formerror">
            
       { <p >{formerror.lastName } </p>}
        </div>
}
           </div>
<div  className="position_relative form-group input_parent" >
           <label htmlFor='emailId'> Email Address</label>
            <input type="text" id="emailId" className="fullInput" name="emailId" value={inputValue.emailId} onChange={changeInput}  />
            {/* <div class="invalid-feedback">
    First name is required
          
           </div> */}
           {
           (Object.keys(formerror).length>0)&&
            <div className="formerror">
            
       { <p >{formerror.email } </p>}
        </div>
}
           </div>
           <div  className=" position_relative flexBox-inner form-group input_parent" >
           <label htmlFor='password'>Password</label>
            <input type="text" id="password" className="fullInput" name="password" value={inputValue.password} onChange={changeInput}  />
            {/* <div class="invalid-feedback">
    First name is required
          
           </div> */}
                {
           (Object.keys(formerror).length>0)&&
            <div className="formerror">
            
       { <p >{formerror.password } </p>}
        </div>
}
        
           </div>
           <div className="sign_in_btn_pos">
{
           (isSignUp) &&
           <button className="site-btn sign_in_btn" id="searchBtn" type='submit'>
            Create Account
            </button>
}
{
            (isSignin) &&
            <button className="site-btn sign_in_btn" id="searchBtn" type='submit'>
            Sign in
            </button>
}

</div>
            </form>
</div>
}
<div className="sign_in_c3">
  <h2>
Orem ipsum dolor sit amet, consectetur adipiscing elit. 

  </h2>
  <p>

Aliquam tristique enim non erat imperdiet, a euismod arcu eleifend. Quisque suscipit lacinia orci eu eleifend. Excepteur sint occaecat 
cupidatat non proident, sunt in culpa qui officia deserunt mollit laborum.
  </p>
</div>

</div>
  </div>
  </div>

</section>



    </>
  )
}

export default SignIn
