import React,{useState,useEffect} from 'react'
import dropin from "braintree-web-drop-in"




const PaymentGteway = ({show,braintreeInstance,handleBuy,braintreeContainerRef}) => {
    // const [braintreeInstance, setBraintreeInstance] = useState(undefined)
  return (
    <>
      <div
            style={{display: `${show ? "block" : "none"}`}}
        >
            <div
            class="sign_in_btn_pos"
            ref={braintreeContainerRef}
                id={"braintree-drop-in-div"}
            />
{/* <div class="sign_in_btn_pos" id="payment_toggle_id"><button class="site-btn sign_in_btn payment-btn" onClick={()=>{setShow(true); $('#payment_toggle_id').css('display','none')}} id=""  >
           <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.6433 3.53564H2.35756C1.48969 3.53564 0.786133 4.2392 0.786133 5.10707V16.8928C0.786133 17.7607 1.48969 18.4642 2.35756 18.4642H19.6433C20.5112 18.4642 21.2147 17.7607 21.2147 16.8928V5.10707C21.2147 4.2392 20.5112 3.53564 19.6433 3.53564Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M0.786133 9.03564H21.2147M14.929 14.5356H17.2861" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
            Debit or Credit Card</button></div> */}
            <button
                className={"braintreePayButton site-btn sign_in_btn payment-btn"}
                type="button" 
                // class="btn btn-primary"
                // disabled={!braintreeInstance}
                onClick={handleBuy}
            >
                {
                    "Confirm & pay"
                }
            </button>
        </div>
    </>
  )
}

export default PaymentGteway
